<template>
	<div id="manage-audio-series">
		<Loader :state="loader_state"></Loader>

		<b-toast
			id="success-toast-new"
			v-model="toast.show"
			title="Information"
			:variant="toast.variant"
			class="alert_box text-white"
			dismissible
			dismiss-label="close"
		>
			{{ toast.msg }}
		</b-toast>

		<div class="row mt-5">
			<div class="col-md-10">
				<b-button class="bg-info" v-b-modal.add-audioc>
					<b-icon-soundwave></b-icon-soundwave>
					Add Audio Collection
				</b-button>
			</div>
			<div class="col-md-2">
				<p class="text-info float-right">Total Records: {{ filterData.length }}</p>
			</div>
		</div>

		<div class="row justify-content-between">
			<div class="col-md-4">
				<b-input-group class="w-75 my-4 form_control float-left">
					<b-form-input type="search" placeholder="Search By Title.." v-model="filter.text"></b-form-input>
					<template v-slot:append>
						<b-input-group-text class="bg-info text-white border-0">
							<b-icon-search></b-icon-search>
						</b-input-group-text>
					</template>
				</b-input-group>
			</div>
			<div class="col-md-2">
				<b-input-group class="my-4 w-100 form_control float-right cursor-pointer">
					<template v-slot:append>
						<b-input-group-text class="bg-info text-white border-0">
							<b-icon-soundwave></b-icon-soundwave>
						</b-input-group-text>
					</template>
					<template v-slot:prepend v-if="filter.lang_id">
						<b-input-group-text class="bg-info text-white border-0" @click="filter.lang_id = null">
							<b-icon-x></b-icon-x>
						</b-input-group-text>
					</template>
					<b-form-select v-model="filter.lang_id">
						<option value="null">Select Language</option>
						<option v-for="(lang, index) in languageData" :key="index" :value="lang.id">
							{{ lang.name }}
						</option>
					</b-form-select>
				</b-input-group>
			</div>
			<div class="col-md-2">
				<b-input-group class="my-4 w-100 form_control float-right cursor-pointer">
					<template v-slot:append>
						<b-input-group-text class="bg-info text-white border-0">
							<b-icon-bounding-box></b-icon-bounding-box>
						</b-input-group-text>
					</template>
					<template v-slot:prepend v-if="filter.subcat_id">
						<b-input-group-text class="bg-info text-white border-0" @click="filter.subcat_id = null">
							<b-icon-x></b-icon-x>
						</b-input-group-text>
					</template>
					<b-form-select v-model="filter.subcat_id">
						<option value="null">Select SubCategory</option>
						<option v-for="(subcat, index) in AllSubCategoriesData" :key="index" :value="subcat.id">
							{{ subcat.name }}
						</option>
					</b-form-select>
				</b-input-group>
			</div>
			<div class="col-md-2">
				<b-input-group class="my-4 w-100 form_control float-right cursor-pointer">
					<template v-slot:append>
						<b-input-group-text class="bg-info text-white border-0">
							<b-icon-person-fill></b-icon-person-fill>
						</b-input-group-text>
					</template>
					<template v-slot:prepend v-if="filter.speaker_id">
						<b-input-group-text class="bg-info text-white border-0" @click="filter.speaker_id = null">
							<b-icon-x></b-icon-x>
						</b-input-group-text>
					</template>
					<b-form-select v-model="filter.speaker_id">
						<option value="null">Select Speaker Name</option>
						<option v-for="(speaker, index) in speakerData" :key="index" :value="speaker.id">
							{{ speaker.name }}
						</option>
					</b-form-select>
				</b-input-group>
			</div>
			<div class="col-md-2">
				<b-form-select
					id="pagination_controller"
					v-model="pagination.per_page"
					class="form_control w-100 my-4 float-md-right"
				>
					<option>Pagination</option>
					<option value="25">25</option>
					<option value="50">50</option>
					<option value="75">75</option>
					<option value="100">100</option>
					<option value="125">125</option>
				</b-form-select>
			</div>
		</div>

		<div class="row">
			<div class="col-md-12">
				<b-table
					class="tableClass text-left"
					borderless
					:fields="fields"
					:items="filterData"
					:per-page="pagination.per_page"
					:current-page="pagination.current_page"
				>
					<template v-slot:cell(title)="data">
						<p class="text-truncate description-max">{{ data.item.title }}</p>
					</template>

					<template v-slot:cell(thumbnail)="data">
						<img :src="baseURL + data.value" alt="video_img" class="tbl_img" />
					</template>

					<template v-slot:cell(description)="data">
						<p class="text-truncate description-max">{{ data.value ? data.value : 'N/A' }}</p>
					</template>

					<template v-slot:cell(hidden)="data">
						<b-icon-eye-slash-fill v-if="data.value" size="md" class="h3 text-danger"></b-icon-eye-slash-fill>
						<b-icon-eye-fill v-else class="h3 text-info"></b-icon-eye-fill>
					</template>

					<template v-slot:cell(status)="data">
						<p>{{ data.value ? 'Completed' : 'InComplete' }}</p>
					</template>

					<template v-slot:cell(subcategory_details)="data">
						{{ data.value.name }}
					</template>

					<template v-slot:cell(speaker_details)="data">
						{{ data.value.name }}
					</template>

					<template v-slot:cell(language_details)="data">
						{{ data.value.name }}
					</template>

					<template v-slot:cell(actions)="data">
						<b-button class="btn btn-info table_edit mx-1" size="sm" @click="edit(data.item)">
							<b-icon-pencil-square></b-icon-pencil-square>
						</b-button>
						<b-button
							class="btn btn-danger mx-1 table_delete"
							size="sm"
							@click="confirm(data.item)"
							v-b-modal.confirm-remove
						>
							<b-icon-trash></b-icon-trash>
						</b-button>
					</template>
					<template v-slot:cell(date)="data">
						{{ getAudioSeriesDate }}
					</template>
					<template v-slot:cell(show_details)="row">
						<b-button variant="info" @click="row.toggleDetails" size="sm" :disabled="!row.item.audios.length">
							<b-icon-chevron-expand></b-icon-chevron-expand>
						</b-button>
					</template>

					<template v-slot:row-details="row">
						<b-table class="tableClass" :items="row.item.audios" :fields="['title', 'audios', 'date']">
							<template v-slot:cell(audios)="data">
								<Audio controls class="table_audio">
									<source :src="baseURL + data.item.audio" />
								</Audio>
							</template>
						</b-table>
					</template>
				</b-table>

				<b-pagination
					v-model="pagination.current_page"
					align="center"
					:per-page="pagination.per_page"
					:total-rows="filterData.length"
				></b-pagination>
			</div>
		</div>

		<b-modal
			@close=";(audioc = {}), (errors = {})"
			id="add-audioc"
			title="Add Audio Collection"
			title-class="text-info"
			header-class="add-modal-header"
			body-class="add-modal-body"
			size="lg"
			centered
			no-close-on-esc
			no-close-on-backdrop
			hide-footer
		>
			<div class="row">
				<div class="col-md-12">
					<b-form>
						<div v-if="!audioc.thumbnail">
							<b-form-file
								class="my-1 form_control"
								accept="image/*"
								v-model="audioc.thumbnail"
								:state="audioc.thumbnail ? true : false"
								placeholder="Thumbnail"
								@input="create_thumb"
							></b-form-file>
							<span v-if="this.errors.thumbnail" class="text-danger">{{...this.errors.thumbnail}}</span>
						</div>
						<div v-else class="position-relative">
							<span class="img_trash" @click="audioc.thumbnail = null">
								<b-icon icon="trash" class="rounded-circle bg-dark img_trash_icon p-2 h1 text-white" size="md"></b-icon>
							</span>
							<img
								v-if="typeof audioc.thumbnail === 'string'"
								:src="baseURL + audioc.thumbnail"
								class="img-thumbnail"
								alt="audioc_img"
							/>
							<img v-else :src="client_url" class="img-thumbnail" alt="audioc_img" />
						</div>

						<label for="">Select Category</label>
						<b-form-select v-model="audioc.category_id" :state="audioc.category_id ? true : false">
							<option v-for="(category, index) in AllCategoriesData" :key="index" :value="category.id">
								{{ category.name }}
							</option>
						</b-form-select>

						<br />
						<label for="">Select SubCategory</label>
						<b-form-select
							class="my-1 form_control"
							v-model="audioc.subcat_id"
							:state="audioc.subcat_id ? true : false"
						>
							<option v-for="(subcat, index) in subCategories" :key="index" :value="subcat.id">
								{{ subcat.name }}
							</option>
						</b-form-select>
						<span v-if="this.errors.subcat_id" class="text-danger">{{...this.errors.subcat_id}}</span>

						<b-form-input
							class="my-1 form_control"
							:maxlength="500"
							v-model="audioc.title"
							:state="audioc.title ? true : false"
							placeholder="Title"
						></b-form-input>
						<span v-if="this.errors.title" class="text-danger">{{...this.errors.title}}</span>

						<b-form-input
							class="my-1 form_control"
							v-model="audioc.source"
							:state="audioc.source ? true : false"
							placeholder="Source"
						></b-form-input>
						<span v-if="this.errors.source" class="text-danger">{{...this.errors.source}}</span>

						<b-datepicker
							class="my-1 form_control"
							v-model="audioc.date"
							:state="audioc.date ? true : false"
							placeholder="Date"
							:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
						></b-datepicker>
						<span v-if="this.errors.date" class="text-danger">{{...this.errors.date}}</span>
						<br />
						<label>Select Speaker</label>
						<b-form-select
							class="my-1 form_control"
							v-model="audioc.speaker_id"
							:state="audioc.speaker_id ? true : false"
						>
							<option v-for="(speaker, index) in speakerData" :key="index" :value="speaker.id">
								{{ speaker.name }}
							</option>
						</b-form-select>
						<span v-if="this.errors.speaker_id" class="text-danger">{{...this.errors.speaker_id}}</span>
						<br />
						<label for="">Select Language</label>
						<b-form-select
							class="my-1 form_control"
							v-model="audioc.language_id"
							:state="audioc.language_id ? true : false"
						>
							<option v-for="(lang, index) in languageData" :key="index" :value="lang.id">
								{{ lang.name }}
							</option>
						</b-form-select>
						<span v-if="this.errors.language_id" class="text-danger">{{...this.errors.language_id}}</span>

						<b-form-textarea
							id="textarea-no-auto-shrink"
							maxlength="2000"
							placeholder="Enter Description"
							rows="5"
							v-model="audioc.description"
							:state="audioc.description ? true : false"
						></b-form-textarea>
						<span v-if="this.errors.description" class="text-danger">{{...this.errors.description}}</span>

						<div class="row">
							<div class="col-md-6">
								<b-form-checkbox
									switch
									size="md"
									class="my-1 float-left form_control"
									v-model="audioc.status"
									placeholder="Status"
								>
									Completed
								</b-form-checkbox>
								<span v-if="this.errors.status" class="text-danger">{{...this.errors.status}}</span>
							</div>
							<div class="col-md-6">
								<div id="check_control" class="form_control float-right text-info">
									<b-icon-eye-slash-fill
										v-if="audioc.hidden === true"
										class="h2 text-danger"
										@click="audioc.hidden = false"
									></b-icon-eye-slash-fill>
									<b-icon-eye-fill v-else class="h2 text-info" @click="audioc.hidden = true"></b-icon-eye-fill>
								</div>
								<span v-if="this.errors.hidden" class="text-danger">{{...this.errors.hidden}}</span>
							</div>
						</div>

						<div class="row mt-2">
							<div class="col-md-6"></div>
							<div class="col-md-6">
								<b-button @click="save" class="w-100 bg-info float-right">Save</b-button>
							</div>
						</div>
					</b-form>
				</div>
			</div>
		</b-modal>

		<!-- Audios -->

		<div class="row mt-5">
			<div class="col-md-10">
				<b-button class="bg-info" v-b-modal.audio_only>
					<b-icon-soundwave></b-icon-soundwave>
					Add Audios
				</b-button>
			</div>
			<div class="col-md-2">
				<p class="text-info float-right">Total Records: {{ filterDataAudios.length ? filterDataAudios.length : 0 }}</p>
			</div>
		</div>

		<div class="row justify-content-between">
			<div class="col-md-4">
				<b-input-group class="w-75 my-4 form_control float-left">
					<b-form-input type="search" placeholder="Search By Audio Title.." v-model="audio_filter.text"></b-form-input>
					<template v-slot:append>
						<b-input-group-text class="bg-info text-white border-0">
							<b-icon-search></b-icon-search>
						</b-input-group-text>
					</template>
				</b-input-group>
			</div>
			<div class="col-md-2 offset-md-2"></div>
			<div class="col-md-2">
				<b-form-select
					id="pagination_controller"
					v-model="pagination.per_page"
					class="form_control w-100 my-4 float-md-right"
				>
					<option>Paginate</option>
					<option value="25">25</option>
					<option value="50">50</option>
					<option value="75">75</option>
					<option value="100">100</option>
					<option value="125">125</option>
				</b-form-select>
			</div>

			<div class="col-md-2">
				<b-input-group class="my-4 w-100 form_control float-right cursor-pointer">
					<template v-slot:append>
						<b-input-group-text class="bg-info text-white border-0">
							<b-icon-soundwave></b-icon-soundwave>
						</b-input-group-text>
					</template>
					<template v-slot:prepend v-if="audio_filter.collection_id">
						<b-input-group-text class="bg-info text-white border-0" @click="audio_filter.collection_id = null">
							<b-icon-x></b-icon-x>
						</b-input-group-text>
					</template>
					<b-form-select v-model="audio_filter.collection_id">
						<option value="">Collection Name</option>
						<option v-for="(audioc, index) in AllAudioCollectionsData" :key="index" :value="audioc.id">
							{{ audioc.title }}
						</option>
					</b-form-select>
				</b-input-group>
			</div>
		</div>

		<div class="row">
			<div class="col-md-12">
				<b-table
					class="tableClass text-left"
					borderless
					:fields="audio_fields"
					:items="filterDataAudios"
					:per-page="audio_pagination.per_page"
					:current-page="audio_pagination.current_page"
				>
					<template v-slot:cell(title)="data">
						<p class="text-truncate description-max">{{ data.item.title }}</p>
					</template>
					<template v-slot:cell(audio)="data">
						<Audio controls class="table_audio">
							<source :src="baseURL + data.value" />
						</Audio>
					</template>
					<template v-slot:cell(collection_details.title)="data">
						<p class="text-truncate description-max">{{ data.value }}</p>
					</template>
					<template v-slot:cell(actions)="data">
						<b-button class="btn btn-info table_edit mx-1" size="sm" @click="edit_audio_only(data.item)">
							<b-icon-pencil-square></b-icon-pencil-square>
						</b-button>
						<b-button
							class="btn btn-danger mx-1 table_delete"
							size="sm"
							@click="confirm_audio_only(data.item)"
							v-b-modal.confirm-remove
						>
							<b-icon-trash></b-icon-trash>
						</b-button>
					</template>
					<template v-slot:cell(date)="data">
						{{ getDate }}
					</template>
				</b-table>

				<b-pagination
					v-model="audio_pagination.current_page"
					align="center"
					:per-page="audio_pagination.per_page"
					:total-rows="filterData.length"
				></b-pagination>
			</div>
		</div>

		<b-modal
			@close=";(audio_only = {}), (errors = {})"
			id="audio_only"
			title="Add Audios To Collection"
			title-class="text-info"
			header-class="add-modal-header"
			body-class="add-modal-body"
			size="md"
			centered
			no-close-on-esc
			no-close-on-backdrop
			hide-footer
		>
			<div class="row">
				<div class="col-md-12">
					<b-form>
						<div v-if="typeof audio_only.audio === 'string'" class="position-relative my-2">
							<b-icon-x-circle
								class="h2 position-absolute span_audio_close"
								@click="audio_only.audio = null"
							></b-icon-x-circle>
							<Audio controls class="table_audio">
								<source :src="baseURL + audio_only.audio" />
							</Audio>
						</div>
						<div v-else>
							<b-form-file
								v-if="!audio_only.audio"
								class="my-1 form_control"
								v-model="audio_only.audio"
								:state="audio_only.audio ? true : false"
								placeholder="Audio"
								@input="create_client_audio"
								accept="audio/mpeg, audio/ogg"
							></b-form-file>
							<div v-else class="position-relative">
								<b-icon-x-circle
									class="h2 position-absolute span_audio_close"
									@click="audio_only.audio = null"
								></b-icon-x-circle>
								<Audio controls class="table_audio">
									<source :src="client_audio" />
								</Audio>
							</div>
						</div>
						<span v-if="this.errors.audio" class="text-danger">{{...this.errors.audio}}</span>

						<b-form-input
							class="my-1 form_control"
							:maxlength="500"
							v-model="audio_only.title"
							:state="audio_only.title ? true : false"
							placeholder="Title"
						></b-form-input>
						<span v-if="this.errors.title" class="text-danger">{{...this.errors.title}}</span>

						<b-datepicker
							class="my-1 form_control"
							v-model="audio_only.date"
							:state="audio_only.date ? true : false"
							placeholder="Date"
							:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
						></b-datepicker>
						<span v-if="this.errors.date" class="text-danger">{{...this.errors.date}}</span>
						<br />
						<label for="">Select Audio Collection</label>
						<b-form-select
							class="my-1 form_control"
							v-model="audio_only.collection_id"
							:state="audio_only.collection_id ? true : false"
						>
							<option v-for="(audioc, index) in AllAudioCollectionsData" :key="index" :value="audioc.id">
								{{ audioc.title }}
							</option>
						</b-form-select>
						<span v-if="this.errors.collection_id" class="text-danger">{{...this.errors.collection_id}}</span>
						<div class="row mt-2 justify-content-end">
							<div class="col-md-4">
								<b-button @click="save_audio_only" class="w-100 bg-info float-right">Save</b-button>
							</div>
						</div>
					</b-form>
				</div>
			</div>
		</b-modal>

		<b-toast id="success-toast" title="Successfull !" variant="success">
			{{ success_message }}
		</b-toast>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import config from '../../assets/config'
import Loader from '../shared/alhaq_loader'
import moment from 'moment'
export default {
	components: { Loader },
	data() {
		return {
			loader_state: false,
			audioc: {
				title: null,
				thumbnail: null,
				description: null,
				hidden: null,
				status: null,
				source: null,
				date: null,
				speaker_id: null,
				language_id: null,
				subcat_id: null,
				category_id: null,
			},
			audio_only: {
				title: null,
				audio: null,
				date: null,
				collection_id: null,
			},
			errors: {},
			toast: {
				show: false,
				msg: '',
				variant: '',
			},
			success_message: null,
			filter: {
				text: '',
				speaker_id: null,
				lang_id: null,
				subcat_id: null,
			},
			audio_filter: {
				text: '',
				collection_id: '',
			},
			api_flag: false,
			baseURL: config.baseURL,
			fields: [
				{ key: 'id', label: 'ID', sortable: true },
				{ key: 'title', label: 'Title', sortable: true },
				{ key: 'date', label: 'Date', sortable: true },
				{ key: 'thumbnail', label: 'Thumbnail' },
				{ key: 'description', label: 'Description' },
				{ key: 'hidden', label: 'Hidden', sortable: true },
				{ key: 'status', label: 'Status', sortable: true },
				{ key: 'speaker_details', label: 'Speaker', sortable: true },
				{ key: 'language_details', label: 'Language', sortable: true },
				{ key: 'subcategory_details', label: 'SubCategory' },
				{ key: 'actions', label: 'Actions' },
				{ key: 'show_details', label: 'Audios' },
				//was working with table
			],
			audio_fields: [
				{ key: 'id', label: 'ID', sortable: true },
				{ key: 'title', label: 'Title', sortable: true },
				{ key: 'audio', label: 'Audio' },
				{ key: 'date', label: 'Date', sortable: true },
				{ key: 'collection_details.title', label: 'Collection Name' },
				{ key: 'actions', label: 'Actions' },
			],
			subCategories: [],
			pagination: {
				current_page: 1,
				per_page: 25,
			},
			audio_pagination: {
				current_page: 1,
				per_page: 25,
			},
			client_url: '',
			client_audio: '',
		}
	},
	mounted() {
		this.loader_state = true
		this.getSubCategory()
		this.getSpeakers()
		this.getLanguages()
		this.getAllAudioCollections({ contentDetails: true })
		this.getAllAudios()
		this.loader_state = false
	},
	methods: {
		save() {
			if (this.audioc.id) {
				let data = new FormData()
				data.append('id', this.audioc.id)
				data.append('title', this.audioc.title)
				data.append('source', this.audioc.source)
				data.append('description', this.audioc.description)
				data.append('hidden', this.audioc.hidden)
				data.append('date', this.audioc.date)
				data.append('status', this.audioc.status ? 1 : 0)
				data.append('hidden', this.audioc.hidden ? 1 : 0)
				data.append('speaker_id', this.audioc.speaker_id)
				data.append('language_id', this.audioc.language_id)
				data.append('subcat_id', this.audioc.subcat_id)
				this.loader_state = true
				this.updateAudioC(data).then((response) => {
					if (response.data.error === true) {
						this.loader_state = false
						this.errors = response.data.data
					} else {
						this.errors = {}
						this.success_message = response.data.message
						this.showToast(response.data.message, 'success')
						this.audioc = {}
						this.$bvModal.hide('add-audioc')
						this.getAllAudioCollections({ contentDetails: true })
						this.loader_state = false
					}
				})
			} else {
				let data = new FormData()
				data.append('thumbnail', this.audioc.thumbnail)
				data.append('title', this.audioc.title)
				data.append('source', this.audioc.source)
				data.append('description', this.audioc.description)
				data.append('hidden', this.audioc.hidden ? 1 : 0)
				data.append('date', this.audioc.date)
				data.append('status', this.audioc.status ? 1 : 0)
				data.append('speaker_id', this.audioc.speaker_id)
				data.append('language_id', this.audioc.language_id)
				data.append('subcat_id', this.audioc.subcat_id)
				this.loader_state = true
				this.storeAudioC(data).then((response) => {
					if (response.data.error) {
						this.loader_state = false
						this.errors = response.data.data
					} else {
						this.errors = {}
						this.success_message = response.data.message
						this.showToast(response.data.message, 'success')
						this.audioc = {}
						this.$bvModal.hide('add-audioc')
						this.getAllAudioCollections({ contentDetails: true })
						this.loader_state = false
					}
				})
			}
		},
		save_audio_only() {
			if (this.audio_only.id) {
				let data = new FormData()
				data.append('id', this.audio_only.id)
				data.append('title', this.audio_only.title)
				if (typeof this.audio_only.audio === 'object') {
					data.append('audio', this.audio_only.audio)
				}
				data.append('date', this.audio_only.date)
				data.append('collection_id', this.audio_only.collection_id)
				this.loader_state = true
				this.updateAudio(data).then((response) => {
					if (response.data.error === true) {
						this.loader_state = false
						this.errors = response.data.data
					} else {
						this.errors = {}
						this.success_message = response.data.message
						this.showToast(response.data.message, 'success')
						this.audio_only = {}
						this.$bvModal.hide('audio_only')
						this.getAllAudios()
						this.getAllAudioCollections({ contentDetails: true })
						this.loader_state = false
					}
				})
			} else {
				let data = new FormData()
				data.append('title', this.audio_only.title)
				data.append('audio', this.audio_only.audio)
				data.append('date', this.audio_only.date)
				data.append('collection_id', this.audio_only.collection_id)
				this.loader_state = true
				this.storeAudio(data).then((response) => {
					if (response.data.error) {
						this.loader_state = false
						this.errors = response.data.data
					} else {
						this.errors = {}
						this.success_message = response.data.message
						this.showToast(response.data.message, 'success')
						this.audio_only = {}
						this.$bvModal.hide('audio_only')
						this.getAllAudios()
						this.getAllAudioCollections({ contentDetails: true })
						this.loader_state = false
					}
				})
			}
		},
		edit_audio_only(payload) {
			this.$bvModal.show('audio_only')
			this.audio_only = payload
		},
		edit(payload) {
			this.$bvModal.show('add-audioc')
			this.audioc = payload
			let cat_id = payload.subcategory_details ? payload.subcategory_details.category_id : ''
			this.$set(this.audioc, 'category_id', cat_id)
		},
		confirm_audio_only(payload) {
			this.$bvModal
				.msgBoxConfirm('Please confirm that you want to delete this resource.', {
					title: 'Confirmation',
					headerClass: 'confirm-header',
					bodyClass: 'confirm-body',
					footerClass: 'confirm-footer',
					okTitle: 'Delete',
					cancelVariant: 'info',
					okVariant: 'danger',
					hideHeaderClose: false,
					centered: true,
				})
				.then((value) => {
					if (value) {
						this.remove_audio_only(payload)
					}
				})
		},
		confirm(payload) {
			this.$bvModal
				.msgBoxConfirm('Please confirm that you want to delete this resource.', {
					title: 'Confirmation',
					headerClass: 'confirm-header',
					bodyClass: 'confirm-body',
					footerClass: 'confirm-footer',
					okTitle: 'Delete',
					cancelVariant: 'info',
					okVariant: 'danger',
					hideHeaderClose: false,
					centered: true,
				})
				.then((value) => {
					if (value) {
						this.remove(payload)
					}
				})
		},
		remove(payload) {
			this.loader_state = true
			this.deleteAudioC({ id: payload.id }).then((response) => {
				if (response.data.error) {
					this.loader_state = false
				} else {
					this.showToast(response.data.message, 'success')
					this.getAllAudioCollections({ contentDetails: true })
					this.getAllAudios()
					this.loader_state = false
				}
			})
		},
		remove_audio_only(payload) {
			this.loader_state = true
			this.deleteAudio({ id: payload.id }).then((response) => {
				if (response.data.error) {
					this.loader_state = false
				} else {
					this.showToast(response.data.message, 'success')
					this.getAllAudios()
					this.getAllAudioCollections({ contentDetails: true })
					this.loader_state = false
				}
			})
		},
		create_thumb() {
			this.client_url = URL.createObjectURL(this.audioc.thumbnail)
			if (this.audioc.id) {
				let data = new FormData()
				data.append('id', this.audioc.id)
				data.append('thumbnail', this.audioc.thumbnail)
				this.loader_state = true
				this.updateAudioCThumb(data).then((response) => {
					if (!response.data.error) {
						this.success_message = response.data.message
						this.showToast(response.data.message, 'success')
						this.getAllAudioCollections({ contentDetails: true })
						this.loader_state = false
					} else {
						this.loader_state = false
					}
				})
			}
		},
		create_client_audio() {
			this.client_audio = URL.createObjectURL(this.audio_only.audio)
		},
		showToast(msg, variant) {
			this.toast.show = true
			this.toast.msg = msg
			this.toast.variant = variant
		},
		...mapActions([
			'getSpeakers',
			'getLanguages',
			'getSubCategory',
			'getAllAudioCollections',
			'getSubCatWithCategoryId',
			'storeAudioC',
			'updateAudioC',
			'updateAudioCThumb',
			'deleteAudioC',
			'getAllAudios',
			'storeAudio',
			'updateAudio',
			'deleteAudio',
		]),
	},
	computed: {
		...mapGetters([
			'AllSubCategoriesData',
			'AllCategoriesData',
			'AllAudioCollectionsData',
			'AllAudiosData',
			'languageData',
			'speakerData',
		]),
		getAudioSeriesDate() {
			return moment(this.AllAudioCollectionsData.date).locale('en-us').format('DD-MM-YYYY')
		},
		getDate() {
			return moment(this.AllAudiosData.date).locale('en-us').format('DD-MM-YYYY')
		},
		filterData: function () {
			return this.AllAudioCollectionsData.filter((item) => {
				return (
					item.title.toLowerCase().includes(this.filter.text.toLowerCase()) &&
					(this.filter.speaker_id ? item.speaker_details.id === this.filter.speaker_id : true) &&
					(this.filter.lang_id ? item.language_details.id === this.filter.lang_id : true) &&
					(this.filter.subcat_id ? item.subcategory_details.id === this.filter.subcat_id : true)
				)
			})
		},
		filterDataAudios: function () {
			return this.AllAudiosData.filter((item) => {
				return (
					item.title.toLowerCase().includes(this.audio_filter.text.toLowerCase()) &&
					(this.audio_filter.collection_id ? item.collection_details.id === this.audio_filter.collection_id : true)
				)
			})
		},
	},
	watch: {
		'audioc.category_id': function (to, from) {
			this.loader_state = true
			this.getSubCatWithCategoryId({ id: this.audioc.category_id }).then((response) => {
				this.loader_state = false
				this.subCategories = response.data.data.sub_categories
			})
		},
	},
}
</script>

<style scoped>
.tbl_img {
	width: 50px;
	height: 50px;
}
.tableClass >>> tbody {
	background-color: #15212d;
	color: white !important;
	border: none !important;
}
.img_trash {
	position: absolute;
	left: 85% !important;
	top: 5% !important;
	cursor: pointer;
}
.img_trash_icon:hover {
	background-color: red !important;
}
.form_control .form-control,
.form_control .custom-select {
	background-color: transparent !important;
	color: #28abe1;
	cursor: pointer;
}
#check_control {
	cursor: pointer !important;
}
.description-max {
	width: 200px !important;
}
.table_audio {
	width: 300px;
	height: 25px;
}
.span_audio_close {
	cursor: pointer;
	left: 90%;
	top: 0;
}
.custom-select-options {
	background-color: #15212d;
	color: #28abe1;
}
</style>
