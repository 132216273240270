<template>
    <div id="tabs">
        <b-tabs pills
        content-class="my-4"
        active-tab-class="active_tabs"
        active-nav-item-class="bg-info"
        >
            <b-tab title="Categories" active>
                <manage-cat></manage-cat>
            </b-tab>
            <b-tab title="Lookups">
                <manage-look-up></manage-look-up>
            </b-tab>
            <b-tab title="General Videos">
                <manage-gen-vdo></manage-gen-vdo>
            </b-tab>
            <b-tab title="Audio Series">
                <manage-audio-c></manage-audio-c>
            </b-tab>
            <b-tab title="Video Series">
                <manage-vds></manage-vds>
            </b-tab>
            <b-tab title="Articles">
                <manage-art></manage-art>
            </b-tab>
            <b-tab title="Books">
               <manage-books></manage-books>
            </b-tab>
            <b-tab title="Apps">
                <manage-apps></manage-apps>
            </b-tab>
            <b-tab title="Settings">
                <manage-social></manage-social>
            </b-tab>
        </b-tabs>
    </div>
</template>

<script>
import manageCat from '../components/private/manage-categories'
import manageVds from '../components/private/manage-videos'
import manageArt from '../components/private/manage-article'
import manageApps from '../components/private/manage-apps'
import manageGenVdo from '../components/private/manage-gen-videos'
import manageAudioC from '../components/private/manage-audio'
import manageBooks from '../components/private/manage-books'
import manageLookUp from '../components/private/manage-lookup'
import manageSocial from '../components/private/manage-social'
export default {

    components: {
        manageCat,
        manageVds,
        manageArt,
        manageApps,
        manageGenVdo,
        manageAudioC,
        manageBooks,
        manageLookUp,
        manageSocial,
    }
}
</script>


<style scoped>

    .tabs  >>> .nav {
        background-color: #15212d;
        padding: 5px;
        border: 1px solid #15212d;

    }
    .tabs >>> li .nav-link {
        color: white;
    }
    .tabs >>> li .active {
        color: white;
    }

</style>