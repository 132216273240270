<template>
  <div  class="manage-books mt-5">

    <Loader :state="loader_state"></Loader>

    <b-toast id="success-toast-new" v-model="toast.show" title="Information" :variant="toast.variant" class="alert_box text-white" dismissible dismiss-label="close">
      {{toast.msg}}
    </b-toast>

    <div class="container-fluid">
      <div class="row  w-100">
          <div class="col-md-10">
            <b-button class="bg-info" v-b-modal.add-books>
              <b-icon-pencil></b-icon-pencil>
                Add Books
            </b-button>
          </div>
          <div class="col-md-2">
            <p class=" text-info float-right">
              Total Records: {{filterBooks.length}}
            </p>
          </div>
      </div>
      <div class="row justify-content-between">
        <div class="col-md-4">
          <b-input-group class="w-75 my-4 form_control float-left">
            <b-form-input type="search" placeholder="Search By Title.." v-model="filter.text"></b-form-input>
            <template v-slot:append>
              <b-input-group-text class="bg-info text-white border-0">
                <b-icon-search></b-icon-search>
              </b-input-group-text>
            </template>
          </b-input-group>
        </div>
        <div class="col-md-2">
          <b-input-group class="my-4 w-100 form_control float-right cursor-pointer">
            <template v-slot:append>
              <b-input-group-text class="bg-info text-white border-0">
                <b-icon-soundwave></b-icon-soundwave>
              </b-input-group-text>
            </template>
            <template v-slot:prepend v-if='filter.lang_id'>
              <b-input-group-text  class="bg-info text-white border-0" @click="filter.lang_id = null">
                <b-icon-x></b-icon-x>
              </b-input-group-text>
            </template>
            <b-form-select v-model="filter.lang_id">
              <option value="null">Select Language</option>
              <option v-for="(lang, index) in languageData" :key=index :value=lang.id>
                {{ lang.name }}
              </option>
            </b-form-select>
          </b-input-group>
        </div>
        <div class="col-md-2">
          <b-input-group class="my-4 w-100 form_control float-right cursor-pointer">
            <template v-slot:append>
              <b-input-group-text class="bg-info text-white border-0">
                <b-icon-bounding-box></b-icon-bounding-box>
              </b-input-group-text>
            </template>
            <template v-slot:prepend v-if='filter.subcat_id'>
              <b-input-group-text  class="bg-info text-white border-0" @click="filter.subcat_id = null">
                <b-icon-x></b-icon-x>
              </b-input-group-text>
            </template>
            <b-form-select v-model="filter.subcat_id">
              <option value="null">Select SubCategory Name</option>
              <option v-for="(subcat, index) in AllSubCategoriesData" :key=index :value=subcat.id>
                {{ subcat.name }}
              </option>
            </b-form-select>
          </b-input-group>
        </div>
        <div class="col-md-2">
          <b-input-group class="my-4 w-100 form_control float-right cursor-pointer">
            <template v-slot:append>
              <b-input-group-text class="bg-info text-white border-0">
                <b-icon-person-fill></b-icon-person-fill>
              </b-input-group-text>
            </template>
            <template v-slot:prepend v-if='filter.author_id'>
              <b-input-group-text  class="bg-info text-white border-0" @click="filter.author_id = null">
                <b-icon-x></b-icon-x>
              </b-input-group-text>
            </template>
            <b-form-select v-model="filter.author_id">
              <option value="null">Select Author Name</option>
              <option v-for="(author, index) in authorData" :key=index :value=author.id>
                {{ author.name }}
              </option>
            </b-form-select>
          </b-input-group>
        </div>
        <div class="col-md-2">
          <b-form-select id="pagination_controller" v-model="pagination.per_page" class="form_control w-100 my-4 float-md-right">
            <option>Paginate</option>
            <option value="25">25</option>
            <option  value="50">50</option>
            <option value="75">75</option>
            <option value="100">100</option>
            <option value="125">125</option>
          </b-form-select>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12 mt-2">
          <b-table class="tableClass text-left" borderless :fields="fields" :items="filterBooks" :per-page="pagination.per_page" :current-page="pagination.current_page" >
            <template v-slot:cell(title)="data">
              <p class="text-truncate description-max">{{data.value}}</p> 
           </template>
            <template v-slot:cell(hidden)="data">
              <b-icon-eye-slash-fill v-if="data.item.hidden" size="md" class="h3 text-danger"></b-icon-eye-slash-fill>
              <b-icon-eye-fill v-else class="h3 text-info"></b-icon-eye-fill>
            </template>
            <template v-slot:cell(thumbnail)="data">
            <img :src=baseURL+data.item.thumbnail alt="book_img" class="apps-thumbnail"/>
          </template>
            <template v-slot:cell(description)="data">
              <p class="text-truncate description-max">{{data.value ? data.value : 'NULL'}}</p>
            </template>
            <template v-slot:cell(subcategory_details)="data">
              {{data.value.name}}
            </template>
            <template v-slot:cell(author_details)="data">
              {{data.value.name}}
            </template>
            <template v-slot:cell(language_details)="data">
                {{data.value.name}}
            </template>
            <template v-slot:cell(actions)="data">
              <b-button class="btn btn-info table_edit mx-2" @click="edit(data.item)">
                <b-icon-pencil-square></b-icon-pencil-square>
              </b-button>
              <b-button class="btn btn-danger mx-2 table_delete" v-b-modal.confirm-remove @click="confirm(data.item)">
                <b-icon-trash></b-icon-trash>
              </b-button>
            </template>
            <template v-slot:cell(date)="data">
                {{ getBooksDate }}
            </template>
          </b-table>
          <b-pagination v-model="pagination.current_page" align="center" :per-page="pagination.per_page" :total-rows="filterBooks.length"></b-pagination>
        </div>
      </div>
    </div>
    <div>
      <b-modal @close="book = {}, errors = {}" id="add-books" title="Add Book" title-class="text-info" header-class="books-header" body-class="books-body" size="lg" centered no-close-on-esc no-close-on-backdrop hide-footer>
        <div class="container-fluid">
          <div class="row ">
            <div class="col-md-12">
              <div class="w-100" v-if="!book.thumbnail">
                <b-form-file  v-model="book.thumbnail" :state="book.thumbnail ? true : false" placeholder="Thumbnail" @input="update_thumb"></b-form-file>
                <span v-if="this.errors.thumbnail" class="text-danger">{{...this.errors.thumbnail}}</span>
              </div>
              <div v-else class="position-relative">
                                 <span class="img_trash" @click="book.thumbnail = null">
                                <b-icon icon="trash" class="rounded-circle bg-dark img_trash_icon p-2 h1 text-white" size="md"></b-icon>
                            </span>
                <img v-if="typeof book.thumbnail === 'string'" :src="baseURL+book.thumbnail" class="img-thumbnail" alt="book_img">
                <img v-else :src="client_url" class="img-thumbnail" alt="book_img">
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <label for="">Select Category Name</label>
              <b-form-select  class="w-100" v-model="book.category_id" :state="book.category_id ? true : false">
                <option v-for="(category,index) in AllCategoriesData" :key=index :value=category.id>
                  {{ category.name }}
                </option>
              </b-form-select>
              <div>
                <span v-if="this.errors.category_id" class="text-danger">{{...this.errors.category_id}}</span>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <label for="">Select Subcategory Name</label>
              <b-form-select class="w-100" v-model="book.subcat_id" :state="book.subcat_id ? true : false">
                <option v-for="(subcat,index) in subCategories" :key=index :value=subcat.id>
                  {{ subcat.name }}
                </option>
              </b-form-select>
              <div>
                <span v-if="this.errors.subcat_id" class="text-danger">{{...this.errors.subcat_id}}</span>
              </div>
            </div>
          </div>
          <div class="row mt-3">
          <div class="col-md-12">
            <label for="">Select Language</label>
            <b-form-select v-model="book.language_id" class="w-100" :state="book.language_id ? true : false">
              <option v-for="(language,index) in languageData" :key="index" :value="language.id">{{language.name}}</option>
            </b-form-select>
            <div>
              <span v-if="this.errors.language_id" class="text-danger">{{...this.errors.language_id}}</span>
            </div>
          </div>
        </div>
          <div class="row mt-3">
            <div class="col-md-12">
                <label >Select Author Name</label>
              <b-form-select v-model="book.author_id" :state="book.author_id ? true : false" class="w-100">
                <option v-for="(author,index) in authorData" :key="index" :value="author.id">{{author.name}}</option>
              </b-form-select>
              <div>
                <span v-if="this.errors.author_id" class="text-danger">{{...this.errors.author_id}}</span>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <div class="w-100">
                <b-input placeholder="Title" :maxlength="500" v-model="book.title" :state="book.title ? true : false"></b-input>
                <span v-if="this.errors.title" class="text-danger">{{...this.errors.title}}</span>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <div class="w-100" v-if="typeof book.book === 'string'">
                <b-icon-x-circle class="h2 position-absolute span_audio_close" @click="book.book = null"></b-icon-x-circle>
               <span> <b-icon-book></b-icon-book>
                <a :href="baseURL+book.book" target="_blank" class="p-2 book_link" >View Book</a></span>
<!--                <b-form-file  v-model="book.book" placeholder="Book"></b-form-file>-->
<!--                <span v-if="this.errors.book" class="text-danger">{{...this.errors.book}}</span>-->
              </div>
              <div v-else>
                <b-form-file v-if="!book.book" class="my-1 form_control" v-model="book.book" :state="book.book ? true : false" placeholder="Book" @input="create_book_url" ></b-form-file>
                <div v-else class="position-relative">
                  <b-icon-x-circle class="h2 position-absolute span_audio_close" @click="book.book = null"></b-icon-x-circle>
                  <span> <b-icon-book></b-icon-book>
                  <a :href="client_book" target="_blank" class="p-2 book_link" >View Book</a></span>
                </div>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <div class="w-100">
                <b-datepicker direction="right to left" v-model="book.date" :state="book.date ? true : false" placeholder="Date" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric'}"></b-datepicker>
                <span v-if="this.errors.date" class="text-danger">{{...this.errors.date}}</span>
              </div>
            </div>
          </div>
          <div class="row mt-3">
            <div class="col-md-12">
              <div class="">
                <b-form-textarea
                        id="textarea-no-auto-shrink"
                        placeholder="Enter Description"
                        maxlength="2000"
                        rows="4"
                        v-model="book.description"
                        :state="book.description ? true : false"
                        :autofocus="false"
                        no-auto-shrink
                ></b-form-textarea>
              </div>
              <span v-if="this.errors.description" class="text-danger">{{...this.errors.description}}</span>
            </div>
          </div>
          <div class="row mt-3 ">
            <div class="col-md-12">
              <div class="w-100">
                <b-icon-eye-slash-fill v-if="book.hidden" class="h2 text-danger" v-model="book.hidden" @click="book.hidden = false"></b-icon-eye-slash-fill>
                <b-icon-eye-fill v-else class="h2 text-info" v-model="book.hidden" @click="book.hidden = true"></b-icon-eye-fill>
              </div>
            </div>
          </div>
          <div class="row ">
            <div class="col-md-8 offset-4 mt-1">
              <div class="w-100">
                <b-button  class="w-50" variant="info" @click="save()">Save</b-button>
              </div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
  </div>
</template>

<script>
import config from "../../assets/config";
import moment from "moment";
import {mapActions, mapGetters} from "vuex";
import Loader from '../shared/alhaq_loader'

export default {
  components: { Loader },
  data(){
    return  {
      loader_state : false,
      date : '',
      errors : {},
      success_message : '',
      client_url : '',
      book: {
        'title' :  null,
        'book' :  null,
        'thumbnail': null,
        'description' : null,
        'hidden' : null,
        'date' : null,
        'subcat_id' : null,
        'category_id' : null,
        'author_id': null,
        'language_id': null,
      },
      toast: {
        show: false,
        msg: '',
        variant: ''
      },
      category_id: '',
      filter: {
        text: '',
        lang_id: null,
        author_id: null,
        subcat_id: null,
      },
      pagination: {
        current_page: 1,
        per_page: 25
      },
      subCategories : [],
      client_book: '',
      baseURL: config.baseURL,
      ModalClose : false,
      fields: [
        { key: 'id', label: 'ID', sortable: true },
        { key: 'title', label: 'Title', sortable: true },
        { key :'date', label: 'Date', sortable: true},
        { key: 'thumbnail', label: 'Thumbnail'},
        { key: 'hidden', label: 'Hidden', sortable: true },
        { key: 'description', label: 'Description' },
        { key: 'subcategory_details', label: 'Sub Category', sortable: true},
        { key: 'author_details', label: 'Author', sortable: true},
        { key :'language_details', label: 'Language', sortable: true},
        { key: 'actions', label: 'Actions'},
      ],
    }
  },
  mounted() {
    this.loader_state = true;
    this.getAllBooks();
    this.getAllAuthors();
    this.getAllLanguages();
    this.getAllSubCategories();
    this.getAllCategories();
    this.loader_state = false;
  },
  filters: {
    moment: function (date) {
      if (date) {
        return moment(date).format("MMM Do YYYY")
      }
    },
  },
  watch: {
    'book.category_id': {
        handler: function (to, from) {
        this.loader_state = true;
        this.getSubCatWithCategoryId({id: this.book.category_id}).then(response => {
        this.loader_state = false;
        this.subCategories = response.data.data.sub_categories
      })
    },
    deep: true 
    },
  },
  computed: {
    ...mapGetters([
      'AllBooksData',
      'languageData',
      'AllSubCategoriesData',
      'AllCategoriesData',
      'authorData'
    ]),
    getBooksDate (){
      return moment(this.AllBooksData.date).locale('en-us').format('DD-MM-YYYY')
    },
    filterBooks: function () {
      return this.AllBooksData.filter(item => {
        return item.title.toLowerCase().includes(this.filter.text.toLowerCase()) &&
                ( this.filter.author_id ? item.author_details.id === this.filter.author_id : true) &&
                ( this.filter.lang_id ? item.language_details.id === this.filter.lang_id : true) &&
                ( this.filter.subcat_id ? item.subcategory_details.id === this.filter.subcat_id : true)
      });
    }
  },
  methods :{
    showToast (msg, variant)  {
      this.toast.show = true
      this.toast.msg = msg
      this.toast.variant = variant
    },
    ...mapActions([
      'getAllBooks',
      'getAllAuthors',
      'getAllLanguages',
      'getAllSubCategories',
      'getAllCategories',
      'storeBook',
      'updateBook',
      'getSubCatWithCategoryId',
      'deleteBook',
      'updateBookThumb'
    ]),
    edit(payload){
      this.$bvModal.show('add-books')
      this.book = payload;
      let cat_id = payload.subcategory_details ? payload.subcategory_details.category_id : null;
      this.$set(this.book, 'category_id', cat_id)
    },
    update_thumb () {
      this.client_url = URL.createObjectURL(this.book.thumbnail)
      if (this.book.id) {
        let data = new FormData()
        data.append('id', this.book.id)
        data.append('book', this.book.book)
        data.append('thumbnail', this.book.thumbnail)
        this.loader_state = true;
        this.updateBookThumb(data).then(response => {
          if (!response.data.error) {
            this.success_message = response.data.message
            this.showToast(response.data.message, 'success')
            this.getAllBooks();
            this.loader_state = false;
          } else {
            this.loader_state = false;
          }
        })
      }

    },
    create_book_url(){
      this.client_book = URL.createObjectURL(this.book.book)
    },
    confirm (payload) {
      this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this resource.', {
        title: 'Confirmation',
        headerClass: 'confirm-header',
        bodyClass: 'confirm-body',
        footerClass: 'confirm-footer',
        okTitle: 'Delete',
        cancelVariant: 'info',
        okVariant: 'danger',
        hideHeaderClose: false,
        centered: true
      }).then(value => {
        if(value) {
          this.remove(payload)
        }
      })

    },
    remove (payload) {
      this.loader_state = true;
      this.deleteBook({'id':payload.id}).then(response => {
        if (response.data.error) {
          this.errors = response.data.data;
          this.loader_state = false;
        } else {
          this.success_message = response.data.message;
          this.getAllBooks();
          this.loader_state = false;
        }
      });
    },
    save(){
      if(this.book.id){
        let data = new FormData();
        data.append('id', this.book.id);
        data.append('category_id', this.book.category_id);
        data.append('subcat_id', this.book.subcat_id);
        data.append('language_id', this.book.language_id);
        data.append('author_id', this.book.author_id);
        data.append('title', this.book.title);
        data.append('book', this.book.book);
        data.append('date', this.book.date);
        data.append('description', this.book.description);
        data.append('hidden', this.book.hidden  ? 1 : 0);
        this.loader_state = true;
        this.updateBook(data).then(response => {
          if (response.data.error ===  true) {
            this.errors = response.data.data;
            this.loader_state = false;
          } else {
            this.errors = {}
            this.success_message = response.data.message;
            this.showToast(response.data.message, 'success');
            this.book = {};
            this.$bvModal.hide('add-books');
            this.getAllBooks();
            this.loader_state = false;
          }
        })
      }
      if(!this.book.id) {
        let data = new FormData();
        data.append('category_id', this.book.category_id);
        data.append('subcat_id', this.book.subcat_id);
        data.append('language_id', this.book.language_id);
        data.append('author_id', this.book.author_id);
        data.append('title', this.book.title);
        data.append('book', this.book.book);
        data.append('thumbnail', this.book.thumbnail);
        data.append('date', this.book.date);
        data.append('description', this.book.description);
        data.append('hidden', this.book.hidden  ? 1 : 0);
        this.loader_state = true;
        this.storeBook(data).then(response => {
          if (response.data.error) {
            this.errors = response.data.data;
            this.loader_state = false;
          } else {
            this.errors = {}
            this.success_message = response.data.message;
            this.$bvModal.hide('add-books');
            this.showToast(response.data.message, 'success')
            this.getAllBooks();
            this.loader_state = false;
          }
        })
      }
    }
  }
}
</script>

<style scoped>
    .tableClass >>> tbody  {
        background-color: #15212d;
        color: white !important;
        border: none !important;
    }
  .apps-thumbnail {
    width: 50px;
    height: 50px;
  }
  .img_trash {
    position: absolute;
    left: 90% !important;
    top: 1% !important;
    cursor: pointer;
  }
  .img_trash_icon:hover {
    background-color: red !important;
  }
  .description-max{
    width: 200px !important;
  }
  .form_control .form-control,
  .form_control .custom-select
  {
    background-color: transparent !important;
    color: #28abe1;
    cursor: pointer;
  }
  .span_audio_close {
    cursor: pointer;
    left: 95%;
    top: 0;
  }
  .book_link{
    color: #495057;
    text-decoration-line: none;
  }
  .custom-select-options{
    background-color: #15212d;
    color: #28abe1;
  }
</style>