<template>
	<div class="row">
		<Loader :state="loader_state"></Loader>
		<b-toast
			id="success-toast-new"
			v-model="toast.show"
			title="Information"
			:variant="toast.variant"
			class="alert_box text-white"
			dismissible
			dismiss-label="close"
		>
			{{ toast.msg }}
		</b-toast>

		<div class="col-md-4">
			<div class="ml-1 row">
				<b-button @click="addSocialModel()" class="mx-0 mb-4 my-2 bg-info">
					<b-icon-pencil></b-icon-pencil>
					Add Social
				</b-button>
			</div>
			<b-table class="tableClass text-left" borderless :fields="fields" :items="socialData">
				<template v-slot:cell(actions)="data">
					<b-button
						size="sm"
						class="btn btn-danger mx-2 table_delete"
						@click="confirm('Social', data.item.id)"
						v-b-modal.confirm-remove
					>
						<b-icon-trash></b-icon-trash>
					</b-button>
				</template>
				<template v-slot:cell(icon)="data">
					<img :src="baseURL + data.item.icon" alt="article_img" class="tbl_img" />
				</template>
			</b-table>
		</div>
		<div class="col-md-4">
			<div class="ml-1 row">
				<b-button @click="addAPIModel()" class="mx-0 mb-4 my-2 bg-info">
					<b-icon-pencil></b-icon-pencil>
					Add Web Services
				</b-button>
			</div>
			<b-table class="tableClass text-left" borderless :fields="webServicesFields" :items="webServiceData">
				<template v-slot:cell(api_key)="data">
					<p class="text-truncate description-max">{{ data.value }}</p>
				</template>
				<template v-slot:cell(actions)="data">
					<b-button
						size="sm"
						class="btn btn-danger mx-2 table_delete"
						@click="confirm('Services', data.item.id)"
						v-b-modal.confirm-remove
					>
						<b-icon-trash></b-icon-trash>
					</b-button>
				</template>
				<template v-slot:cell(created_at)="data">
					{{ getServicesDate }}
				</template>
			</b-table>
		</div>
		<div class="col-md-4">
			<div class="ml-1 row">
				<b-button @click="addSliderModel()" class="mx-0 mb-4 my-2 bg-info">
					<b-icon-pencil></b-icon-pencil>
					Add Slider
				</b-button>
			</div>
			<b-table class="tableClass text-left" borderless :fields="sliderFields" :items="sliderData">
				<template v-slot:cell(actions)="data">
					<b-button
						size="sm"
						class="btn btn-danger mx-2 table_delete"
						@click="confirm('Slider', data.item.id)"
						v-b-modal.confirm-remove
					>
						<b-icon-trash></b-icon-trash>
					</b-button>
				</template>
				<template v-slot:cell(url)="data">
					<img :src="baseURL + data.value" alt="article_img" class="tbl_img" />
				</template>
				<template v-slot:cell(created_at)="data">
					{{ getSliderDate }}
				</template>
			</b-table>
		</div>

		<div class="row">
			<div class="col-md-12">
				<div class="ml-1">
					<b-button @click="addDonationModel()" class="mx-0 mb-4 my-2 bg-info">
						<b-icon-pencil></b-icon-pencil>
						Add Donation
					</b-button>
				</div>
				<b-table class="tableClass text-left" borderless :fields="donationFields" :items="donationData">
					<template v-slot:cell(actions)="data">
						<b-button
							size="sm"
							class="btn btn-danger mx-2 table_delete"
							@click="confirm('Donation', data.item.id)"
							v-b-modal.confirm-remove
						>
							<b-icon-trash></b-icon-trash>
						</b-button>
					</template>
				</b-table>
			</div>
		</div>

		<!-- adding social model  start -->
		<div>
			<b-modal
				id="add-social_model"
				hide-footer
				title="Add Social"
				centered
				no-close-on-esc
				no-close-on-backdrop
				hide-footer
			>
				<b-form @submit.prevent="addSocialToDB" enctype="multipart/form-data">
					<div class="d-block text-left">
						<b-form-input v-model="socalUrl" :state="socalUrl ? true : false" placeholder="Url"> </b-form-input>
						<span v-if="this.socialUrlFlag" class="text-danger"> URL is Required </span>
						<b-form-file
							class="mt-2"
							placeholder="Icon"
							v-model="socalIcon"
							:state="socalIcon ? true : false"
							accept="image/*"
						>
						</b-form-file>
					</div>
					<span v-if="this.socialIconFlag" class="text-danger"> Image is Required </span>
					<b-button class="mt-3" variant="outline-info" block type="submit"> Add Social </b-button>
				</b-form>
			</b-modal>
		</div>
		<!-- adding social model end -->
		<!-- adding API model  start -->
		<div>
			<b-modal
				id="add-api_model"
				hide-footer
				title="Add API Key"
				centered
				no-close-on-esc
				no-close-on-backdrop
				hide-footer
			>
				<div class="d-block text-left">
					<b-form-input v-model="api_key" :state="api_key ? true : false" placeholder="API Key"> </b-form-input>
					<span v-if="this.apiKeyFlag" class="text-danger"> API Key Required </span>
				</div>
				<b-button class="mt-3" variant="outline-info" block @click="addAPI"> Add API </b-button>
			</b-modal>
		</div>
		<!-- adding social API end -->
		<!-- adding Slider model  start -->
		<div>
			<b-modal
				id="add-slider-model"
				hide-footer
				title="Add Slider Image"
				centered
				no-close-on-esc
				no-close-on-backdrop
				hide-footer
			>
				<b-form enctype="multipart/form-data">
					<div class="d-block text-center">
						<b-form-file v-model="slider" :state="slider ? true : false" accept="image/*"> </b-form-file>
						<span v-if="sliderFlag" class="text-danger"> Slider is Required </span>
					</div>
					<b-button class="mt-3" variant="outline-info" block @click="addSliderToDB()"> Add Slider </b-button>
				</b-form>
			</b-modal>
		</div>
		<!-- adding social API end -->

		<div>
			<b-modal
				id="add-donation-model"
				hide-footer
				title="Add Donation"
				centered
				no-close-on-esc
				no-close-on-backdrop
				hide-footer
			>
				<b-form enctype="multipart/form-data">
					<b-form-input v-model="donationLink" :state="donationLink ? true : false" placeholder="Link"> </b-form-input>
					<b-button class="mt-3" variant="outline-info" block @click="addDonation"> Add Donation </b-button>
				</b-form>
			</b-modal>
		</div>

		<b-toast id="success-toast-social" title="Successfull !" variant="success">
			{{ success_message }}
		</b-toast>
	</div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import config from '../../assets/config'
import Loader from '../shared/alhaq_loader'
import moment from 'moment'
export default {
	components: { Loader },
	data() {
		return {
			loader_state: false,
			baseURL: config.baseURL,
			sliderFlag: false,
			apiKeyFlag: false,
			donationKeyFlag: false,
			socialUrlFlag: false,
			socialIconFlag: false,
			socalIcon: null,
			socalUrl: null,
			slider: null,
			donationLink: null,
			api_key: '',
			success_message: '',
			toast: {
				show: false,
				msg: '',
				variant: '',
			},
			errors: {},
			fields: [
				{ key: 'url', label: 'URL' },
				{ key: 'icon', label: 'Icon' },
				{ key: 'actions', label: 'Actions' },
			],
			webServicesFields: [
				{ key: 'api_key', label: 'API Key' },
				{ key: 'actions', label: 'Actions' },
			],
			sliderFields: [
				{ key: 'url', label: 'Slider' },
				{ key: 'actions', label: 'Actions' },
			],
			donationFields: [
				{ key: 'link', label: 'Link' },
				{ key: 'actions', label: 'Actions' },
			],
			donationData: [],
		}
	},
	mounted() {
		this.loader_state = true
		this.getSocial()
		this.getSlider()
		this.getDonation().then((response) => {
			this.donationData = response.data.data
		})
		this.getWebService().then(() => {
			this.loader_state = false
		})
	},
	computed: {
		...mapGetters(['socialData', 'webServiceData', 'sliderData']),
		getServicesDate() {
			return moment(this.webServiceData.created_at).locale('en-us').format('YYYY-MM-DD')
		},
		getSliderDate() {
			return moment(this.sliderData.created_at).locale('en-us').format('YYYY-MM-DD')
		},
	},
	methods: {
		...mapActions([
			'getSocial',
			'addSocial',
			'deleteSocial',
			'getWebService',
			'addWebService',
			'deleteWebService',
			'getSlider',
			'addSlider',
			'deleteSlider',
			'getDonation',
			'storeDonation',
			'deleteDonation',
		]),
		addSocialModel() {
			this.socialUrlFlag = false
			this.socialIconFlag = false
			this.$bvModal.show('add-social_model')
		},
		addAPIModel() {
			this.apiKeyFlag = false
			this.$bvModal.show('add-api_model')
		},
		addSliderModel() {
			this.sliderFlag = false
			this.$bvModal.show('add-slider-model')
		},
		addDonationModel() {
			this.donationKeyFlag = false
			this.$bvModal.show('add-donation-model')
		},
		addDonation() {
			if (this.donationLink !== null) {
				this.storeDonation({ link: this.donationLink })
					.then((response) => {
						this.getDonation().then((response) => {
							this.donationData = response.data.data
						})
					})
					.finally(() => {
						this.donationLink = null
						this.$bvModal.hide('add-donation-model')
					})
			}
		},

		addSocialToDB() {
			if (this.socalUrl !== null && this.socalIcon !== null) {
				let addSocialData = new FormData()
				addSocialData.append('url', this.socalUrl)
				addSocialData.append('icon', this.socalIcon)
				this.loader_state = true
				this.addSocial({ data: addSocialData }).then((response) => {
					if (response.data.error) {
						this.errors = response.data.data
						this.loader_state = false
					} else {
						this.errors = {}
						this.success_message = response.data.message
						this.showToast(response.data.message, 'success')
						this.$bvModal.hide('add-social_model')
						this.getSocial()
						this.loader_state = false
					}
				})
			} else if (this.socalUrl == null) {
				this.socialUrlFlag = true
				this.socialIconFlag = false
			} else if (this.socalIcon == null) {
				this.socialIconFlag = true
				this.socialUrlFlag = false
			}
		},
		addSliderToDB() {
			if (this.slider !== null) {
				let addSliderData = new FormData()
				addSliderData.append('slider', this.slider)
				this.loader_state = true
				this.addSlider({ data: addSliderData }).then((response) => {
					if (response.data.error) {
						this.errors = response.data.data
						this.loader_state = false
					} else {
						this.errors = {}
						this.success_message = response.data.message
						this.showToast(response.data.message, 'success')
						this.$bvModal.hide('add-slider-model')
						this.getSlider()
						this.loader_state = false
					}
				})
			} else {
				this.sliderFlag = true
			}
		},
		addAPI() {
			if (this.api_key !== '') {
				let addAPI = {
					api_key: this.api_key,
				}
				this.loader_state = true
				this.addWebService({ data: addAPI }).then((response) => {
					if (response.data.error) {
						this.errors = response.data.data
						this.loader_state = false
					} else {
						this.errors = {}
						this.success_message = response.data.message
						this.showToast(response.data.message, 'success')
						this.$bvModal.hide('add-api_model')
						this.getWebService()
						this.loader_state = false
					}
				})
			} else {
				this.apiKeyFlag = true
			}
		},
		showToast(msg, variant) {
			this.toast.show = true
			this.toast.msg = msg
			this.toast.variant = variant
		},
		confirm(title, payload) {
			this.$bvModal
				.msgBoxConfirm('Please confirm that you want to delete this resource.', {
					title: 'Confirmation',
					headerClass: 'confirm-header',
					bodyClass: 'confirm-body',
					footerClass: 'confirm-footer',
					okTitle: 'Delete',
					cancelVariant: 'info',
					okVariant: 'danger',
					hideHeaderClose: false,
					centered: true,
				})
				.then((value) => {
					if (value) {
						this.remove(title, payload)
					}
				})
		},
		remove(title, id) {
			if (title === 'Social') {
				this.loader_state = true
				this.deleteSocial({ id: id }).then((response) => {
					if (response.data.error) {
						this.errors = response.data.data
						this.loader_state = false
					} else {
						this.errors = {}
						this.success_message = response.data.message
						this.$bvToast.show('success-toast-lookup')
						this.getSocial()
						this.loader_state = false
					}
				})
			} else if (title === 'Services') {
				this.loader_state = true
				this.deleteWebService({ id: id }).then((response) => {
					if (response.data.error) {
						this.errors = response.data.data
						this.loader_state = false
					} else {
						this.errors = {}
						this.success_message = response.data.message
						this.$bvToast.show('success-toast-lookup')
						this.getWebService()
						this.loader_state = false
					}
				})
			} else if (title === 'Donation') {
				this.loader_state = true
				this.deleteDonation(id).then((response) => {
					this.loader_state = false
					this.getDonation().then((response) => {
						this.donationData = response.data.data
					})
				})
			} else {
				this.loader_state = true
				this.deleteSlider({ id: id }).then((response) => {
					if (response.data.error) {
						this.errors = response.data.data
						this.loader_state = false
					} else {
						this.errors = {}
						this.success_message = response.data.message
						this.$bvToast.show('success-toast-lookup')
						this.getSlider()
						this.loader_state = false
					}
				})
			}
		},
	},
}
</script>

<style>
.tbl_img {
	width: 30px !important;
	height: 30px !important;
}
.description-max {
	width: 200px !important;
}
</style>
