<template>
    <div id="manage-article">
        <Loader :state="loader_state"></Loader>

        <b-toast id="success-toast-new" v-model="toast.show" title="Information" :variant="toast.variant" class="alert_box text-white" dismissible dismiss-label="close">
            {{toast.msg}}
        </b-toast>

        <div class="row mt-5">
            <div class="col-md-10">
                <b-button class="bg-info" v-b-modal.compose-article>
                    <b-icon-pencil></b-icon-pencil>
                    Write An Article
                </b-button>
            </div>
            <div class="col-md-2">
                <p class=" text-info float-right">
                    Total Records: {{filterData.length}}
                </p>
            </div>
        </div>

        <div class="row justify-content-between">
            <div class="col-md-4">
                <b-input-group class="w-75 my-4 form_control float-left">
                    <b-form-input type="search" placeholder="Search By Title.." v-model="filter.text"></b-form-input>
                    <template v-slot:append>
                        <b-input-group-text class="bg-info text-white border-0">
                            <b-icon-search></b-icon-search>
                        </b-input-group-text>
                    </template>
                </b-input-group>
            </div>
            <div class="col-md-2">
                <b-input-group class="my-4 w-100 form_control float-right cursor-pointer">
                    <template v-slot:append>
                        <b-input-group-text class="bg-info text-white border-0">
                            <b-icon-soundwave></b-icon-soundwave>
                        </b-input-group-text>
                    </template>
                    <template v-slot:prepend v-if='filter.lang_id'>
                        <b-input-group-text  class="bg-info text-white border-0" @click="filter.lang_id = null">
                            <b-icon-x></b-icon-x>
                        </b-input-group-text>
                    </template>
                    <b-form-select v-model="filter.lang_id">
                        <option value="null">Select Language</option>
                        <option v-for="(lang, index) in languageData" :key=index :value=lang.id>
                            {{ lang.name }}
                        </option>
                    </b-form-select>
                </b-input-group>
            </div>
            <div class="col-md-2">
                <b-input-group class="my-4 w-100 form_control float-right cursor-pointer">
                    <template v-slot:append>
                        <b-input-group-text class="bg-info text-white border-0">
                            <b-icon-bounding-box></b-icon-bounding-box>
                        </b-input-group-text>
                    </template>
                    <template v-slot:prepend v-if='filter.subcat_id'>
                        <b-input-group-text  class="bg-info text-white border-0" @click="filter.subcat_id = null">
                            <b-icon-x></b-icon-x>
                        </b-input-group-text>
                    </template>
                    <b-form-select v-model="filter.subcat_id">
                        <option value="null">Select SubCategory Name</option>
                        <option v-for="(subcat, index) in AllSubCategoriesData" :key=index :value=subcat.id>
                            {{ subcat.name }}
                        </option>
                    </b-form-select>
                </b-input-group>
            </div>
            <div class="col-md-2">
                <b-input-group class="my-4 w-100 form_control float-right cursor-pointer">
                    <template v-slot:append>
                        <b-input-group-text class="bg-info text-white border-0">
                            <b-icon-person-fill></b-icon-person-fill>
                        </b-input-group-text>
                    </template>
                    <template v-slot:prepend v-if='filter.author_id'>
                        <b-input-group-text  class="bg-info text-white border-0" @click="filter.author_id = null">
                            <b-icon-x></b-icon-x>
                        </b-input-group-text>
                    </template>
                    <b-form-select v-model="filter.author_id">
                        <option value="null">Select Author Name</option>
                        <option v-for="(author, index) in authorData" :key=index :value=author.id>
                            {{ author.name }}
                        </option>
                    </b-form-select>
                </b-input-group>
            </div>
            <div class="col-md-2">
                <b-form-select id="pagination_controller" v-model="pagination.per_page" class="form_control w-100 my-4 float-md-right">
                    <option>Pagination</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                    <option value="75">75</option>
                    <option value="100">100</option>
                    <option value="125">125</option>
                </b-form-select>
            </div>
        </div>

        <div class="row">
            <div class="col-md-12">
                <b-table class="tableClass text-left" borderless :fields="fields" :items="filterData" :per-page="pagination.per_page" :current-page="pagination.current_page">
                    <template v-slot:cell(title)="data">
                        <p class="text-truncate description-max">{{data.value}}</p> 
                     </template>
                    <template v-slot:cell(thumbnail)="data">
                        <img :src=baseURL+data.value alt="article_img" class="tbl_img"/>
                    </template>
                    <template v-slot:cell(hidden)="data">
                            <b-icon-eye-slash-fill v-if="data.value" size="md" class="h3 text-danger"></b-icon-eye-slash-fill>
                            <b-icon-eye-fill v-else class="h3 text-info"></b-icon-eye-fill>
                    </template>
                    <template v-slot:cell(subcategory_details)="data">
                        {{data.value.name}}
                    </template>
                    <template v-slot:cell(author_details)="data">
                        {{data.value.name}}
                    </template>
                    <template v-slot:cell(language_details)="data">
                        {{data.value.name}}
                    </template>
                    <template v-slot:cell(actions)="data">
                        <b-button class="btn btn-info table_edit mx-2" @click="edit(data.item)">
                            <b-icon-pencil-square></b-icon-pencil-square>
                        </b-button>
                        <b-button class="btn btn-danger mx-2 table_delete" @click="confirm(data.item)" v-b-modal.confirm-remove>
                            <b-icon-trash></b-icon-trash>
                        </b-button>
                    </template>
                    <template v-slot:cell(date)="data">
                        {{ getArticlesDate }}
                    </template>
                </b-table>
                <b-pagination v-model="pagination.current_page" align="center" :per-page="pagination.per_page" :total-rows="filterData.length"></b-pagination>
            </div>
        </div>

        <b-modal @close="article = {}, errors = {}" id="compose-article" title="Write An Article" title-class="text-info"  size="xl"  centered no-close-on-esc no-close-on-backdrop hide-footer>
            <div class="row">
                <div ref="editorWrapper" class="col-md-8">
                    <quill-editor v-model="article.body"
                                  ref="myQuillEditor"
                                  :options="options"
                                 >
                    </quill-editor>
                    <span v-if="this.errors.body" class="text-danger">{{...this.errors.body}}</span>

                </div>
                <div class="col-md-4">
                    <b-form>
                        <div v-if="!article.thumbnail">
                            <b-form-file class="my-1 form_control" v-model="article.thumbnail" :state="article.thumbnail ? true : false" placeholder="Thumbnail" @input="create_thumb"></b-form-file>
                            <span v-if="this.errors.thumbnail" class="text-danger">{{...this.errors.thumbnail}}</span>
                        </div>
                        <div v-else class="position-relative">
                            <span class="img_trash" @click="article.thumbnail = null">
                                <b-icon icon="trash" class="rounded-circle bg-dark img_trash_icon p-2 h1 text-white" size="md"></b-icon>
                            </span>
                            <img v-if="typeof article.thumbnail === 'string'" :src="baseURL+article.thumbnail" class="img-thumbnail" alt="article_img">
                            <img v-else :src="client_url" class="img-thumbnail" alt="article_img">
                        </div>
                        <label for="">Select Category</label>
                        <b-form-select v-model="article.category_id" :state="article.category_id ? true : false">
                            <option v-for="(category , index) in AllCategoriesData" :key=index :value=category.id>
                                {{ category.name }}
                            </option>
                        </b-form-select>
                        
                        <label for="">Select SubCategory</label>
                        <b-form-select class="my-1 form_control" v-model="article.subcat_id" :state="article.subcat_id ? true : false">
                            <option  v-for="(subcat,index) in subCategories" :key=index :value=subcat.id>
                                {{ subcat.name }}
                            </option>
                        </b-form-select>
                        <span v-if="this.errors.subcat_id" class="text-danger">{{...this.errors.subcat_id}}</span>

                        <b-form-input  class="my-1 form_control" :maxlength="500" v-model="article.title" :state="article.title ? true : false" placeholder="Title"></b-form-input>
                        <span v-if="this.errors.title" class="text-danger">{{...this.errors.title}}</span>


                        <b-datepicker class="my-1 form_control" v-model="article.date" :state="article.date ? true : false" placeholder="Date" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric'}"></b-datepicker>
                        <span v-if="this.errors.date" class="text-danger">{{...this.errors.date}}</span>
                        <br/>
                        <label for="">Select Author Name</label>
                        <b-form-select class="my-1 form_control" v-model="article.author_id" :state="article.author_id ? true : false">
                            <option  v-for="(author,index) in authorData" :key=index :value=author.id>
                                {{ author.name }}
                            </option>
                        </b-form-select>
                        <span v-if="this.errors.author_id" class="text-danger">{{...this.errors.author_id}}</span>
                        <br/>
                        <label for="">Select Language</label>
                        <b-form-select class="my-1 form_control" v-model="article.language_id" :state="article.language_id ? true : false">
                            <option  v-for="(lang,index) in languageData" :key=index :value=lang.id>
                                {{ lang.name }}
                            </option>
                        </b-form-select>
                        <span v-if="this.errors.language_id" class="text-danger">{{...this.errors.language_id}}</span>

                        <div class="row mt-2">
                            <div class="col-md-6">
                                <b-input-group id="check_control" class="form_control text-info" :state="article.hidden ? true : false">
                                    <b-icon-eye-slash-fill v-if="article.hidden" class="h2 text-danger" @click="article.hidden = false"></b-icon-eye-slash-fill>
                                    <b-icon-eye-fill v-else class="h2 text-info" @click="article.hidden = true"></b-icon-eye-fill>
                                </b-input-group>
                                <span v-if="this.errors.hidden" class="text-danger">{{...this.errors.hidden}}</span>
                            </div>
                            <div class="col-md-6">
                                <b-button @click="save" class="w-100 bg-info float-right">Save</b-button>
                            </div>
                        </div>
                    </b-form>
                </div>
            </div>
        </b-modal>

        <b-toast id="success-toast" title="Successfull !" variant="success">
            {{ success_message }}
        </b-toast>
    </div>
</template>

<script>

import { mapActions, mapGetters } from 'vuex'
import config from '../../assets/config'
import Loader from '../shared/alhaq_loader'
import moment from 'moment'

export default {
    components: { Loader },
    data () {
        return {
            loader_state: false,
            editor: '',
            article: {
                'title': null,
                'body': null,
                'thumbnail': null,
                'hidden': false,
                'date': null,
                'category_id' : null,
                'author_id': null,
                'subcat_id': null,
                'language_id': null,
            },
            errors: {},
            toast: {
                show: false,
                msg: '',
                variant: ''
            },
            success_message: null,
            filter: {
                text: '',
                lang_id: null,
                author_id: null,
                subcat_id: null,
            },
            baseURL: config.baseURL,
            fields: [   
                { key: 'id', label: 'ID', sortable: true},
                { key: 'title', label: 'Title', sortable: true},
                { key: 'date', label: 'Date', sortable: true},
                { key: 'thumbnail', label: 'Thumbnail'},
                { key: 'hidden', label: 'Hidden', sortable: true},
                { key: 'author_details', label: 'Author', sortable: true},
                { key: 'language_details', label: 'Language', sortable: true},
                { key: 'subcategory_details', label: 'Sub Category', sortable: true},
                { key: 'actions', label: 'Actions'},
            ],
            client_url: '',
            subCategories: [],
            pagination: {
                current_page: 1,
                per_page: 25
            },
            options: {
                placeholder: 'Compose An Article...',
                modules: {
                    toolbar: [
                        ['bold', 'italic', 'underline', 'strike'],
                        ['blockquote', 'code-block'],

                        [{ 'header': 1 }, { 'header': 2 }],
                        [{ 'list': 'ordered'}, { 'list': 'bullet' }],
                        [{ 'script': 'sub'}, { 'script': 'super' }],
                        [{ 'indent': '-1'}, { 'indent': '+1' }],
                        [{ 'direction': 'rtl' }],

                        [{ 'size': ['small', false, 'large', 'huge'] }],

                        [{ 'color': [] }, { 'background': [] }],
                        [{ 'font': [] }],
                        [{ 'align': [] }],
                        ['image', 'link', 'video'],
                        ['clean']
                    ]
                },
                theme: 'snow'
            }
        }
    },
    mounted() {
        this.loader_state = true;
        this.getSubCategory()
        this.getAuthors()
        this.getLanguages()
        this.getAllArticles()
        this.loader_state = false;
    },
    methods: {
        save () {
            if (this.article.id) {
                let data = new FormData();
                data.append('id', this.article.id)
                data.append('title', this.article.title)
                data.append('body', JSON.stringify(this.article.body))
                data.append('hidden', this.article.hidden)
                data.append('date', this.article.date)
                data.append('hidden', this.article.hidden ? 1 : 0)
                data.append('author_id', this.article.author_id)
                data.append('subcat_id', this.article.subcat_id)
                data.append('language_id', this.article.language_id)
                this.loader_state = true;
                this.updateArticle(data).then(response => {
                    this.loader_state = false;
                    if (response.data.error ===  true) {
                        this.errors = response.data.data;
                    } else {
                        this.errors = {}
                        this.success_message = response.data.message;
                        this.showToast(response.data.message, 'success')
                        this.article = {}
                        this.$bvModal.hide('compose-article')
                        this.getAllArticles()
                    }
                })
            } else {
                let data = new FormData();
                data.append('title', this.article.title)
                data.append('body', JSON.stringify(this.article.body))
                data.append('thumbnail', this.article.thumbnail)
                data.append('hidden', this.article.hidden)
                data.append('date', this.article.date)
                data.append('hidden', this.article.hidden ? 1 : 0)
                data.append('author_id', this.article.author_id)
                data.append('subcat_id', this.article.subcat_id)
                data.append('language_id', this.article.language_id)
                this.loader_state = true;
                this.storeArticle(data).then(response => {
                    this.loader_state = false;
                    if (response.data.error) {
                        this.errors = response.data.data;
                    } else {
                        this.errors = {}
                        this.success_message = response.data.message;
                        this.showToast(response.data.message, 'success')
                        this.article = {}
                        this.$bvModal.hide('compose-article')
                        this.getAllArticles()
                    }
                })
            }
        },
        edit (payload) {
          this.$bvModal.show('compose-article')
          let cat_id = payload.subcategory_details ? payload.subcategory_details.category_id : ''
          this.article = payload;
          this.$set(this.article, 'category_id', cat_id)
          this.article.body = JSON.parse(payload.body)
        },
        confirm (payload) {
            this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this resource.', {
                title: 'Confirmation',
                headerClass: 'confirm-header',
                bodyClass: 'confirm-body',
                footerClass: 'confirm-footer',
                okTitle: 'Delete',
                cancelVariant: 'info',
                okVariant: 'danger',
                hideHeaderClose: false,
                centered: true
            }).then(value => {
                if(value) {
                    this.remove(payload)
                }
            })

        },
        remove (payload) {
            this.loader_state = true;
            this.deleteArticle({'id':payload.id}).then(response => {
                this.loader_state = false;
                if (response.data.error) {
                    console.error(response)
                } else {
                    console.log(response.data.message)
                    this.getAllArticles()
                }
            });
        },
        create_thumb () {
            this.client_url = URL.createObjectURL(this.article.thumbnail)
            if (this.article.id) {
                let data = new FormData()
                data.append('id', this.article.id)
                data.append('thumbnail', this.article.thumbnail)
                this.loader_state = true;
                this.updateArticleThumb(data).then(response => {
                    this.loader_state = false;
                    if (!response.data.error) {
                        this.success_message = response.data.message
                        this.showToast(response.data.message, 'success')
                        this.getAllArticles()
                    }
                })
            }

        },
        showToast (msg, variant)  {
            this.toast.show = true
            this.toast.msg = msg
            this.toast.variant = variant
        },
        ...mapActions([
            'getSubCategory',
            'getLanguages',
            'getAuthors',
            'getAllArticles',
            'getSubCatWithCategoryId',
            'storeArticle',
            'updateArticle',
            'updateArticleThumb',
            'deleteArticle'
        ]),
    },
    computed: {
        ...mapGetters([
            'languageData',
            'authorData',
            'AllSubCategoriesData',
            'AllCategoriesData',
            'AllArticlesData'
        ]),
        getArticlesDate() {
            return moment(this.AllArticlesData.date).locale('en-us').format('DD-MM-YYYY')
        },
        filterData: function () {
            return this.AllArticlesData.filter(item => {
                return item.title.toLowerCase().includes(this.filter.text.toLowerCase()) &&
                        ( this.filter.author_id ? item.author_details.id === this.filter.author_id : true) &&
                        ( this.filter.lang_id ? item.language_details.id === this.filter.lang_id : true) &&
                        ( this.filter.subcat_id ? item.subcategory_details.id === this.filter.subcat_id : true)
            });
        }
    },
    watch: {
        'article.category_id': function (to, from) {
            this.loader_state = true;
            this.getSubCatWithCategoryId({id: this.article.category_id}).then(response => {
                this.loader_state = false;
                this.subCategories = response.data.data.sub_categories
            })
        },
    }
}
</script>

<style scoped>
    #editor {
        background-color: white !important;
    }
    .description-max{
    width: 200px !important;
    }
    .tbl_img {
        width: 50px;
        height: 50px;
    }
    .tableClass >>> tbody  {
        background-color: #15212d;
        color: white !important;
        border: none !important;
    }
    .img_trash {
        position: absolute;
        left: 85% !important;
        top: 5% !important;
        cursor: pointer;
    }
    .img_trash_icon:hover {
        background-color: red !important;
    }
    .form_control .form-control,
    .form_control .custom-select
    {
        background-color: transparent !important;
        color: #28abe1;
        cursor: pointer;
    }
    #check_control {
        cursor: pointer !important;
    }
    .custom-select-options{
        background-color: #15212d;
        color: #28abe1;
    }

</style>