<template>
  <div id="manage-gen-vdo">
    <Loader :state="loader_state"></Loader>
    <b-toast id="success-toast-new" v-model="toast.show" title="Information" :variant="toast.variant" class="alert_box text-white" dismissible dismiss-label="close">
      {{toast.msg}}
    </b-toast>


      <div class="row mt-5">
        <div class="col-md-10">
          <b-button class="bg-info" v-b-modal.add-gen-video>
            <b-icon-camera-video></b-icon-camera-video>
              Add General Video
          </b-button>
        </div>
          <div class="col-md-2">
            <p class=" text-info float-right">
              Total Records: {{filterData.length}}
            </p>
          </div>
    </div>

    <div class="row justify-content-between">
        <div class="col-md-4">
          <b-input-group class="w-75 my-4 form_control float-left">
            <b-form-input type="search" placeholder="Search By Title.." v-model="filter.text"  ></b-form-input>
            <template v-slot:append>
              <b-input-group-text class="bg-info text-white border-0">
                <b-icon-search></b-icon-search>
              </b-input-group-text>
            </template>
          </b-input-group>
        </div>
        <div class="col-md-2 offset-md-4">
          <b-input-group class="my-4 w-100 form_control float-right cursor-pointer">
            <template v-slot:append>
              <b-input-group-text class="bg-info text-white border-0">
                <b-icon-soundwave></b-icon-soundwave>
              </b-input-group-text>
            </template>
            <template v-slot:prepend v-if='filter.lang_id'>
              <b-input-group-text  class="bg-info text-white border-0" @click="filter.lang_id = null">
                <b-icon-x></b-icon-x>
              </b-input-group-text>
            </template>
            <b-form-select v-model="filter.lang_id">
              <option value="null">Select Language</option>
              <option v-for="(lang, index) in languageData" :key=index :value=lang.id>
                {{ lang.name }}
              </option>
            </b-form-select>
          </b-input-group>
        </div>
      <div class="col-md-2">
        <b-form-select id="pagination_controller" v-model="pagination.per_page" class="form_control w-100 my-4 float-md-right">
          <option>Pagination</option>
          <option value="25">25</option>
          <option value="50">50</option>
          <option value="75">75</option>
          <option value="100">100</option>
          <option value="125">125</option>
        </b-form-select>
      </div>
    </div>

    <div class="row">
      <div class="col-md-12">
        <b-table class="tableClass text-left" borderless :fields="fields" :items="filterData" :per-page="pagination.per_page" :current-page="pagination.current_page">
          <template v-slot:cell(title)="data">
            <p class="text-truncate description-max">{{data.item.title}}</p> 
         </template>
          <template v-slot:cell(thumbnail)="data">
            <img :src=baseURL+data.value alt="video_img" class="tbl_img"/>
          </template>

          <template v-slot:cell(description)="data">
              <p class="text-truncate description-max">{{data.value ? data.value : 'NULL'}}</p>
          </template>

          <template v-slot:cell(hidden)="data">
            <b-icon-eye-slash-fill v-if="data.value" size="md" class="h3 text-danger"></b-icon-eye-slash-fill>
            <b-icon-eye-fill v-else class="h3 text-info"></b-icon-eye-fill>
          </template>
          <template v-slot:cell(speaker)="data">
            {{data.value}}
          </template>
          <template v-slot:cell(language_details)="data">
            {{data.value.name}}
          </template>
          <template v-slot:cell(actions)="data">
            <b-button class="btn btn-info table_edit mx-2" @click="edit(data.item)">
              <b-icon-pencil-square></b-icon-pencil-square>
            </b-button>
            <b-button class="btn btn-danger mx-2 table_delete" @click="confirm(data.item)" v-b-modal.confirm-remove>
              <b-icon-trash></b-icon-trash>
            </b-button>
          </template>
            <template v-slot:cell(date)="data">
              {{ getGeneralVideos }}
            </template>
        </b-table>

<!--        <b-pagination v-model="pagination.current_page" align="center" :per-page="pagination.per_page" :total-rows="filterData.length"></b-pagination>-->
      </div>
    </div>

    <b-modal @close="gvideo = {}, errors = {}" id="add-gen-video" title="Add General Video" title-class="text-info" header-class="add-modal-header" body-class="add-modal-body" size="lg"  centered no-close-on-esc no-close-on-backdrop hide-footer>
      <div class="row justify-content-center">
        <div class="col-md-12">
          <b-form>
            <div v-if="!gvideo.thumbnail">
              <b-form-file class="my-1 form_control" v-model="gvideo.thumbnail" placeholder="Thumbnail" @input="create_thumb"></b-form-file>
              <span v-if="this.errors.thumbnail" class="text-danger">{{...this.errors.thumbnail}}</span>
            </div>
            <div v-else class="position-relative">
                            <span class="img_trash" @click="gvideo.thumbnail = null">
                                <b-icon icon="trash" class="rounded-circle bg-dark img_trash_icon p-2 h1 text-white" size="md"></b-icon>
                            </span>
              <img v-if="typeof gvideo.thumbnail === 'string'" :src="baseURL+gvideo.thumbnail" class="img-thumbnail" alt="gvideo_img">
              <img v-else :src="client_url" class="img-thumbnail" alt="gvideo_img">
            </div>


            <b-form-input class="my-1 form_control" :maxlength="max" v-model="gvideo.title" :state="gvideo.title ? true : false" placeholder="Title"></b-form-input>
             <span v-if="this.errors.title" class="text-danger">{{...this.errors.title}}</span>

            <b-form-input  class="my-1 form_control" v-model="gvideo.url" :state="gvideo.url ? true : false" placeholder="paste the youtube video id (i.e sUPlCuce9Ik)"></b-form-input>
            <span v-if="this.errors.url" class="text-danger">{{...this.errors.url}}</span>


            <b-datepicker class="my-1 form_control" :state="gvideo.date ? true : false" v-model="gvideo.date" placeholder="Date" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric'}"></b-datepicker>
            <span v-if="this.errors.date" class="text-danger">{{...this.errors.date}}</span>
          
            <b-form-input  class="my-1 form_control" v-model="gvideo.speaker" :state="gvideo.speaker ? true : false" placeholder="Speaker Name"></b-form-input>
            <span v-if="this.errors.speaker" class="text-danger">{{...this.errors.speaker}}</span>
            <br/>
            <label for="">Select Language</label>
            <b-form-select class="my-1 form_control" v-model="gvideo.language_id" :state="gvideo.language_id ? true : false">
              <option v-for="(lang,index) in languageData" :key=index :value=lang.id>
                {{ lang.name }}
              </option>
            </b-form-select>
            <span v-if="this.errors.language_id" class="text-danger">{{...this.errors.language_id}}</span>

            <b-form-textarea
                    id="textarea-no-auto-shrink"
                    maxlength="2000"
                    v-model="gvideo.description"
                    placeholder="Enter Description"
                    rows="5"
                    :state="gvideo.description ? true : false"
            ></b-form-textarea>
            <span v-if="this.errors.description" class="text-danger">{{...this.errors.description}}</span>

            <div class="row mt-2">
              <div class="col-md-6">
                <b-input-group id="check_control" class="form_control text-info">
                  <b-icon-eye-slash-fill v-if="gvideo.hidden === true" class="h2 text-danger" @click="gvideo.hidden = false"></b-icon-eye-slash-fill>
                  <b-icon-eye-fill v-else class="h2 text-info" @click="gvideo.hidden = true"></b-icon-eye-fill>
                </b-input-group>
                <span v-if="this.errors.hidden" class="text-danger">{{...this.errors.hidden}}</span>
              </div>
              <div class="col-md-6">
                <b-button @click="save" class="w-100 bg-info float-right">Save</b-button>
              </div>
            </div>
          </b-form>
        </div>
      </div>
    </b-modal>

    <b-toast id="success-toast" title="Successfull !" variant="success">
      {{ success_message }}
    </b-toast>
  </div>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'
  import config from '../../assets/config'
  import Loader from '../shared/alhaq_loader'
  import moment from 'moment'

export default {
  components: { Loader },
    data () {
      return {
        max: 500,
        loader_state: false,
        gvideo: {
          'title': "",
          'url': null,
          'thumbnail': null,
          'description': null,
          'hidden': null,
          'date': null,
          'speaker': null,
          'language_id': null,
        },
        errors: {},
        toast: {
          show: false,
          msg: '',
          variant: ''
        },
        success_message: null,
        filter: {
          text: '',
          lang_id: null,
          subcat_id: null,
        },
        baseURL: config.baseURL,
        fields: [
          { key: 'id', label: 'ID', sortable: true},
          { key: 'title', label: 'Title', sortable: true},
          { key: 'date', label: 'Date', sortable: true},
          { key: 'thumbnail', label: 'Thumbnail'},
          { key: 'description', label: 'Description'},
          { key: 'hidden', label: 'Hidden', sortable: true},
          { key: 'speaker', label: 'Speaker', sortable: true},
          { key: 'language_details', label: 'Language', sortable: true},
          { key: 'actions', label: 'Actions'},
                //was working with table
        ],
        pagination: {
          current_page: 1,
          per_page: 25
        },
        client_url: '',
      }
    },
    mounted() {
      this.loader_state = true;
      this.getLanguages()
      this.getAllGenVideos()
      this.loader_state = false;
    },
    methods: {
      save () {
        if (this.gvideo.id) {
          let data = new FormData();
          data.append('id', this.gvideo.id)
          data.append('title', this.gvideo.title)
          data.append('url', this.gvideo.url)
          data.append('description', this.gvideo.description)
          data.append('hidden', this.gvideo.hidden)
          data.append('date', this.gvideo.date)
          data.append('hidden', this.gvideo.hidden ? 1 : 0)
          data.append('speaker', this.gvideo.speaker)
          data.append('language_id', this.gvideo.language_id)
          this.loader_state = true;
          this.updateVideo(data).then(response => {
            if (response.data.error ===  true) {
              this.errors = response.data.data;
              this.loader_state = false;
            } else {
              this.errors = {}
              this.success_message = response.data.message;
              this.showToast(response.data.message, 'success')
              this.gvideo = {}
              this.$bvModal.hide('add-gen-video')
              this.getAllGenVideos()
              this.loader_state = false;
            }
          })
        } else {
          let data = new FormData();
          if (this.gvideo.thumbnail) {
            data.append('thumbnail', this.gvideo.thumbnail)
          }
          data.append('title', this.gvideo.title)
          data.append('url', this.gvideo.url)
          data.append('description', this.gvideo.description)
          data.append('hidden', this.gvideo.hidden)
          data.append('date', this.gvideo.date)
          data.append('hidden', this.gvideo.hidden ? 1 : 0)
          data.append('speaker', this.gvideo.speaker)
          data.append('language_id', this.gvideo.language_id)
          this.loader_state = true;
          this.storeVideo(data).then(response => {
            if (response.data.error) {
              this.errors = response.data.data;
              this.loader_state = false;
            } else {
              this.errors = {}
              this.success_message = response.data.message;
              this.showToast(response.data.message, 'success')
              this.gvideo = {}
              this.$bvModal.hide('add-gen-video')
              this.getAllGenVideos()
              this.loader_state = false;
            }
          })
        }
      },
      showToast (msg, variant)  {
        this.toast.show = true
        this.toast.msg = msg
        this.toast.variant = variant
      },
      edit (payload) {
        this.$bvModal.show('add-gen-video')
        this.gvideo = payload;
      },
      confirm (payload) {
        this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this resource.', {
          title: 'Confirmation',
          headerClass: 'confirm-header',
          bodyClass: 'confirm-body',
          footerClass: 'confirm-footer',
          okTitle: 'Delete',
          cancelVariant: 'info',
          okVariant: 'danger',
          hideHeaderClose: false,
          centered: true
        }).then(value => {
          if(value) {
            this.remove(payload)
          }
        })

      },
      remove (payload) {
        this.loader_state = true;
        this.deleteVideo({'id':payload.id}).then(response => {
          if (response.data.error) {
            this.loader_state = false;
          } else {
            this.showToast(response.data.message, 'success')
            this.getAllGenVideos()
            this.loader_state = false;
          }
        });
      },
      create_thumb () {
        this.client_url = URL.createObjectURL(this.gvideo.thumbnail)
        if (this.gvideo.id) {
          let data = new FormData()
          data.append('id', this.gvideo.id)
          data.append('thumbnail', this.gvideo.thumbnail)
          this.loader_state = true;
          this.updateVideoThumb(data).then(response => {
            if (!response.data.error) {
              this.success_message = response.data.message
              this.showToast(response.data.message, 'success')
              this.getAllGenVideos()
              this.loader_state = false;
            } else {
              this.loader_state = false;
            }
          })
        }

      },
      ...mapActions([
        'getLanguages',
        'getSubCategory',
        'getAllGenVideos',
        'storeVideo',
        'updateVideo',
        'updateVideoThumb',
        'deleteVideo'
      ]),
    },
    computed: {
      ...mapGetters([
        'AllGenVideosData',
        'languageData'
      ]),
      getGeneralVideos(){
                return moment(this.AllGenVideosData.date).locale('en-us').format('DD-MM-YYYY')
            },
      filterData: function () {
        return this.AllGenVideosData.filter(item => {
          return item.title.toLowerCase().includes(this.filter.text.toLowerCase()) &&
                  ( this.filter.lang_id ? item.language_details.id === this.filter.lang_id : true)
        });
      }
    }
}
</script>

<style scoped>
  .tbl_img {
    width: 50px;
    height: 50px;
  }
  .tableClass >>> tbody  {
    background-color: #15212d;
    color: white !important;
    border: none !important;
  }
  .tableClass >>> thead tr th[aria-colindex="4"],
  .description-max{
    width: 200px !important;
  }
  .img_trash {
    position: absolute;
    left: 85% !important;
    top: 5% !important;
    cursor: pointer;
  }
  .img_trash_icon:hover {
    background-color: red !important;
  }
  .form_control .form-control,
  .form_control .custom-select
  {
    background-color: transparent !important;
    color: #28abe1;
    cursor: pointer;
  }
  #check_control {
    cursor: pointer !important;
  }
  .custom-select-options{
    background-color: #15212d;
    color: #28abe1;
  }
</style>