<template>
  <div id="manage-lookups">
    <Loader :state="loader_state"></Loader>
      <b-toast id="success-toast-new" v-model="toast.show" title="Information" :variant="toast.variant" class="alert_box text-white" dismissible dismiss-label="close">
          {{toast.msg}}
      </b-toast>

  <div class="row">
    <div class="col-md-4">
      <b-input-group class="w-75 my-4 form_control float-left">
        <b-form-input type="search" placeholder="Search Language By Title.." v-model="filterLanguage.text"></b-form-input>
          <template v-slot:append>
            <b-input-group-text class="bg-info text-white border-0">
              <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
        </b-input-group>
    </div>
     <div class="col-md-4">
      <b-input-group class="w-75 my-4 form_control float-left">
        <b-form-input type="search" placeholder="Search Speaker By Title.." v-model="filterSpeaker.text"></b-form-input>
          <template v-slot:append>
            <b-input-group-text class="bg-info text-white border-0">
              <b-icon-search></b-icon-search>
          </b-input-group-text>
        </template>
      </b-input-group>
    </div>
     <div class="col-md-4">
        <b-input-group class="w-75 my-4 form_control float-left">
          <b-form-input type="search" placeholder="Search Author By Title.." v-model="filterAuthor.text"></b-form-input>
            <template v-slot:append>
              <b-input-group-text class="bg-info text-white border-0">
                <b-icon-search></b-icon-search>
            </b-input-group-text>
          </template>
        </b-input-group>
    </div>
  </div>
  <div class="row">
    <div class="col-md-4">
      <div class="ml-1 row">
        <b-button @click="addModel('' ,'Add Language', 'Language')" class="mx-0 mb-4 my-2 bg-info">
          <b-icon-pencil></b-icon-pencil>
            Add Language
        </b-button>
      </div>
      <b-table class="tableClass text-left" borderless :fields="fields" :items="filterLanguages">
        <template v-slot:cell(actions)="data">
            <b-button class="btn btn-info table_edit mx-2" @click="addModel(data.item, 'UpdateThumb Language', 'Language')">
                <b-icon-pencil-square></b-icon-pencil-square>
            </b-button>
            <b-button class="btn btn-danger mx-2 table_delete" @click="confirm('Language', data.item.id)" v-b-modal.confirm-remove> 
                <b-icon-trash></b-icon-trash>
            </b-button>
        </template>
        <template v-slot:cell(created_at)="data">
          {{ getLanguageDate }}
        </template>
      </b-table>

    </div>
    <div class="col-md-4">
      <div class="ml-1 row">
        <b-button @click="addModel('' ,'Add Speaker', 'Speaker')" class="mx-0 mb-4 my-2 bg-info">
          <b-icon-pencil></b-icon-pencil>
            Add Speaker
        </b-button>
      </div>
      <b-table class="tableClass text-left" borderless :fields="fields" :items="filterSpeakers">
        <template v-slot:cell(actions)="data">
            <b-button class="btn btn-info table_edit mx-2" @click="addModel(data.item, 'UpdateThumb Speaker', 'Speaker')">
                <b-icon-pencil-square></b-icon-pencil-square>
            </b-button>
            <b-button class="btn btn-danger mx-2 table_delete" @click="confirm('Speaker', data.item.id)" v-b-modal.confirm-remove> 
                <b-icon-trash></b-icon-trash>
            </b-button>
        </template>
        <template v-slot:cell(created_at)="data">
          {{ getSpeakerDate }}
        </template>
      </b-table>
    </div>
    <div class="col-md-4">
      <div class="ml-1 row">
        <b-button @click="addModel('' ,'Add Author', 'Author')" class="mx-0 mb-4 my-2 bg-info">
          <b-icon-pencil></b-icon-pencil>
            Add Author
        </b-button>
      </div>
      <b-table class="tableClass text-left" borderless :fields="fields" :items="filterAuthors">
        <template v-slot:cell(actions)="data">
            <b-button class="btn btn-info table_edit mx-2" @click="addModel(data.item, 'UpdateThumb Author', 'Author')">
                <b-icon-pencil-square></b-icon-pencil-square>
            </b-button>
            <b-button class="btn btn-danger mx-2 table_delete" @click="confirm('Author', data.item.id)" v-b-modal.confirm-remove> 
                <b-icon-trash></b-icon-trash>
            </b-button>
        </template>
        <template v-slot:cell(created_at)="data">
          {{ getAuthorDate }}
        </template>
      </b-table>
    </div>
      <!-- adding vlookup model  start -->
    <div>
      <b-modal ref="add-modal" hide-footer :title="modelTitle" centered no-close-on-esc no-close-on-backdrop hide-footer>
        <div class="d-block text-center">
          <b-form-input
              v-model="name"
              :state="name ? true : false"
              :placeholder="placeholder">
          </b-form-input>
          <span v-if="this.errors.name" class="text-danger">{{...this.errors.name}}</span>
        </div>
        <b-button class="mt-3" variant="outline-info" block @click="addToDataBase"> {{ modelFunction }} </b-button>
      </b-modal>
    </div>
  <!-- adding vlookup model end -->
    <b-toast id="success-toast-lookup" title="Successfull !" variant="success">
      {{ success_message }}
    </b-toast>
  </div>
  </div>  
</template>

<script>
  import { mapGetters, mapActions } from 'vuex'
  import moment from 'moment'
  import Loader from '../shared/alhaq_loader'
  export default {
    components: { Loader },
    data(){
    return {
      loader_state: false,
      id: '',
      name: '',
      errors: {},
        toast: {
            show: false,
            msg: '',
            variant: ''
        },
      success_message: null,
      placeholder: 'Placeholder',
      modelTitle: 'Dummy Test',
      modelFunction: 'Dummy Function',
      fields: [
          { key: 'name', label: 'Name', sortable: true  },
          { key: 'created_at', label: 'Date' },
          { key: 'actions', label: 'Actions' },
        ],
        filterAuthor: {
        text: '' 
        },
        filterLanguage: {
        text: '' 
        },
        filterSpeaker: {
        text: '' 
        },
      }
    },
    mounted (){
    },
  computed: {
    ...mapGetters([
      'languageData',
      'speakerData',
      'authorData',
    ]),
    getAuthorDate(){
      return moment(this.authorData.created_at).locale('en-us').format('DD-MM-YYYY')
    },
    getSpeakerDate(){
      return moment(this.authorData.created_at).locale('en-us').format('DD-MM-YYYY')
    },
    getLanguageDate(){
      return moment(this.authorData.created_at).locale('en-us').format('DD-MM-YYYY')
    },
    filterAuthors: function(){
      return this.authorData.filter(item => {
        return item.name.toLowerCase().includes(this.filterAuthor.text.toLowerCase())
      });
    },
    filterSpeakers(){
      return this.speakerData.filter(item => {
        return item.name.toLowerCase().includes(this.filterSpeaker.text.toLowerCase())
      })  
    },
    filterLanguages(){
      return this.languageData.filter(item =>{
        return item.name.toLowerCase().includes(this.filterLanguage.text.toLowerCase())
      })
    }
  },
  methods: {
    ...mapActions([
      'getLanguages',
      'addLanguage',
      'updateLanguage',
      'deleteLanguage',
      'getSpeakers',
      'addSpeaker',
      'updateSpeaker',
      'deleteSpeaker',
      'getAuthors',
      'addAuthor',
      'updateAuthor',
      'deleteAuthor',
    ]),
    confirm (title,payload) {
        this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this resource.', {
            title: 'Confirmation',
            headerClass: 'confirm-header',
            bodyClass: 'confirm-body',
            footerClass: 'confirm-footer',
            okTitle: 'Delete',
            cancelVariant: 'info',
            okVariant: 'danger',
            hideHeaderClose: false,
            centered: true
        }).then(value => {
            if(value) {
                this.remove(title,payload)
            }
        })
      },
    remove(title, id){
      if(title === 'Author'){
        this.loader_state = true;
        this.deleteAuthor({id: id}).then(response =>{
          if(response.data.error){
            this.errors = response.data.data
            this.loader_state = false;
          } else {
            this.errors = {}
            this.success_message = response.data.message
            this.showToast(response.data.message, 'success')
            this.getAuthors()
            this.loader_state = false;
          }
        })
      } else if(title === 'Speaker'){
        this.loader_state = true;
        this.deleteSpeaker({id: id}).then(response =>{
          if(response.data.error){
            this.errors = response.data.data
            this.loader_state = false;
          } else {
            this.errors = {}
            this.success_message = response.data.message
            this.showToast(response.data.message, 'success')
            this.getSpeakers()
            this.loader_state = false;
          }
        })
      } else {
        this.loader_state = true;
        this.deleteLanguage({id: id}).then(response =>{
          if(response.data.error){
            this.errors = response.data.data
            this.loader_state = false;
          } else {
            this.errors = {}
            this.success_message = response.data.message
            this.showToast(response.data.message, 'success')
            this.getLanguages()
            this.loader_state = false;
          }
        })
      }
    },
    addModel(data, value, placeholder){
      this.name = ''
      if(data === ''){
        this.modelTitle = value
        this.modelFunction = value
        this.placeholder = placeholder + ' Name'
      } else {
        this.id = data.id
        this.name = data.name
        this.modelTitle = value
        this.modelFunction = value
        this.placeholder = placeholder + ' Name'
      }
        this.$refs['add-modal'].show()
    },
    addToDataBase(){
      if(this.modelFunction === 'Add Language'){
        let addlang = {
          name: this.name
        }
        this.loader_state = true;
        this.addLanguage({data: addlang}).then(response =>{
          if(response.data.error){
            this.errors = response.data.data
            this.loader_state = false;
          } else {
            this.errors = {}
            this.success_message = response.data.message
            this.showToast(response.data.message, 'success')
            this.$refs['add-modal'].hide()
            this.getLanguages()
            this.loader_state = false;
          }
        }) 
      } else if(this.modelFunction === 'UpdateThumb Language'){
        let updatelang = {
          name: this.name,
          id: this.id
        }
        this.loader_state = true;
         this.updateLanguage({data: updatelang}).then(response =>{
          if(response.data.error){
            this.errors = response.data.data
            this.loader_state = false;
          } else {
            this.errors = {}
            this.success_message = response.data.message
            this.showToast(response.data.message, 'success')
            this.$refs['add-modal'].hide()
            this.getLanguages()
            this.loader_state = false;
          }
        })
      } else if(this.modelFunction === 'Add Speaker'){
        let addspeaker = {
          name: this.name
        }
        this.loader_state = true;
         this.addSpeaker({data: addspeaker}).then(response =>{
          if(response.data.error){
            this.errors = response.data.data
            this.getSpeakers()
            this.loader_state = false;
          } else {
            this.errors = {}
            this.success_message = response.data.message
            this.getSpeakers()
            this.showToast(response.data.message, 'success')
            this.$refs['add-modal'].hide()
            this.loader_state = false;
          }
        })
      } else if(this.modelFunction === 'UpdateThumb Speaker'){
        let updatespeaker = {
          name: this.name,
          id: this.id
        }
        this.loader_state = true;
         this.updateSpeaker({data: updatespeaker}).then(response =>{
          if(response.data.error){
            this.errors = response.data.data
            this.loader_state = false;
          } else {
            this.errors = {}
            this.success_message = response.data.message
            this.showToast(response.data.message, 'success')
            this.$refs['add-modal'].hide()
            this.getSpeakers()
            this.loader_state = false;
          }
        })
      } else if(this.modelFunction === 'Add Author'){
         let addauthor = {
          name: this.name
        }
        this.loader_state = true;
         this.addAuthor({data: addauthor}).then(response =>{
          if(response.data.error){
            this.errors = response.data.data
            this.loader_state = false;
          } else {
            this.errors = {}
            this.success_message = response.data.message
            this.showToast(response.data.message, 'success')
            this.$refs['add-modal'].hide()
            this.getAuthors()
            this.loader_state = false;
          }
        })
      } else if(this.modelFunction === 'UpdateThumb Author'){
         let updateauthor = {
          name: this.name,
          id: this.id
        }
        this.loader_state = true;
         this.updateAuthor({data: updateauthor}).then(response =>{
          if(response.data.error){
            this.errors = response.data.data
            this.loader_state = false;
          } else {
            this.errors = {}
            this.success_message = response.data.message
            this.showToast(response.data.message, 'success')
            this.$refs['add-modal'].hide()
            this.getAuthors()
            this.loader_state = false;
          }
        })
      }
    },
      showToast (msg, variant)  {
          this.toast.show = true
          this.toast.msg = msg
          this.toast.variant = variant
      },
  }, 
}
</script>

<style scope>
  .tableClass {
      background-color: #15212d;
      color: #28abe1 !important;
      border: none !important;
  }
  .form_control .form-control,
  .form_control .custom-select
  {
      background-color: transparent !important;
      color: #28abe1;
      cursor: pointer;
  }
</style>