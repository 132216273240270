<template>
<div id="manage-videos">
    <Loader :state="loader_state"></Loader>
    <b-toast id="success-toast-new" v-model="toast.show" title="Information" :variant="toast.variant" class="alert_box text-white" dismissible dismiss-label="close">
        {{toast.msg}}
    </b-toast>

    <div class="row mt-5">
            <div class="col-md-12">
                <b-button @click="addNewVideo('','Add Video')" class="bg-info" v-b-modal.compose-playlist>
                    <b-icon-pencil></b-icon-pencil>
                    Add Playlist
                </b-button>
            </div>
    </div>

    <div class="row justify-content-between">
        <div class="col-md-4">
            <b-input-group class="w-75 my-4 form_control float-left">
                <b-form-input type="search" placeholder="Search By Title.." v-model="filter.text"></b-form-input>
                <template v-slot:append>
                    <b-input-group-text class="bg-info text-white border-0">
                        <b-icon-search></b-icon-search>
                    </b-input-group-text>
                </template>
            </b-input-group>
        </div>
        <div class="col-md-2">
            <b-input-group class="my-4 w-100 form_control float-right cursor-pointer">
                <template v-slot:append>
                    <b-input-group-text class="bg-info text-white border-0">
                        <b-icon-soundwave></b-icon-soundwave>
                    </b-input-group-text>
                </template>
                <template v-slot:prepend v-if='filter.lang_id'>
                    <b-input-group-text  class="bg-info text-white border-0" @click="filter.lang_id = null">
                        <b-icon-x></b-icon-x>
                    </b-input-group-text>
                </template>
                <b-form-select v-model="filter.lang_id">
                    <option value="null">Select Language</option>
                    <option v-for="(lang, index) in languageData" :key=index :value=lang.id>
                        {{ lang.name }}
                    </option>
                </b-form-select>
            </b-input-group>
        </div>
        <div class="col-md-2">
            <b-input-group class="my-4 w-100 form_control float-right cursor-pointer">
                <template v-slot:append>
                    <b-input-group-text class="bg-info text-white border-0">
                        <b-icon-bounding-box></b-icon-bounding-box>
                    </b-input-group-text>
                </template>
                <template v-slot:prepend v-if='filter.subcat_id'>
                    <b-input-group-text  class="bg-info text-white border-0" @click="filter.subcat_id = null">
                        <b-icon-x></b-icon-x>
                    </b-input-group-text>
                </template>
                <b-form-select v-model="filter.subcat_id">
                    <option value="null">Select SubCategory Name</option>
                    <option v-for="(subcat, index) in AllSubCategoriesData" :key=index :value=subcat.id>
                        {{ subcat.name }}
                    </option>
                </b-form-select>
            </b-input-group>
        </div>
        <div class="col-md-2">
            <b-input-group class="my-4 w-100 form_control float-right cursor-pointer">
                <template v-slot:append>
                    <b-input-group-text class="bg-info text-white border-0">
                        <b-icon-person-fill></b-icon-person-fill>
                    </b-input-group-text>
                </template>
                <template v-slot:prepend v-if='filter.speaker_id'>
                    <b-input-group-text  class="bg-info text-white border-0" @click="filter.speaker_id = null">
                        <b-icon-x></b-icon-x>
                    </b-input-group-text>
                </template>
                <b-form-select v-model="filter.speaker_id">
                    <option value="null">Select Speaker</option>
                    <option v-for="(speaker, index) in AllSpeakersData" :key=index :value=speaker.id>
                        {{ speaker.name }}
                    </option>
                </b-form-select>
            </b-input-group>
        </div>
        <div class="col-md-2">
            <b-form-select id="pagination_controller" v-model="pagination.per_page" class="form_control w-100 my-4 float-md-right">
                <option>Pagination</option>
                <option value="25">25</option>
                <option value="50">50</option>
                <option value="75">75</option>
                <option value="100">100</option>
                <option value="125">125</option>
            </b-form-select>
        </div>
    </div>


    <div class="row">
        <div class="col-md-12">
            <b-table class="tableClass text-left" borderless :fields="fields" :items="filterData" :per-page="pagination.per_page" :current-page="pagination.current_page">
                <template v-slot:cell(title)="data">
                    <p class="text-truncate description-max">{{data.value}}</p> 
                 </template>
                <template v-slot:cell(thumbnail)="data">
                    <img :src=baseURL+data.value alt="video__series_img" class="tbl_img"/>
                </template>
                <template v-slot:cell(hidden)="data">
                    <b-icon-eye-slash-fill v-if="data.value" size="md" class="h3 text-danger"></b-icon-eye-slash-fill>
                    <b-icon-eye-fill v-else class="h3 text-info"></b-icon-eye-fill>
                </template>
                <template v-slot:cell(actions)="data">
                    <b-button class="btn btn-info table_edit mx-2" @click="edit('Update Video',data.item,)">
                        <b-icon-pencil-square></b-icon-pencil-square>
                    </b-button>
                    <b-button class="btn btn-danger mx-2 table_delete" @click="confirm(data.item.id)" v-b-modal.confirm-remove> 
                        <b-icon-trash></b-icon-trash>
                    </b-button>
                </template>
                <template v-slot:cell(status)="data">
                     {{ data.item.status == 1 ? 'Completed' : 'InComplete' }}
                </template>
                <template v-slot:cell(date)="data">
                    {{ getVideoSeriesDate }}
                </template>
            </b-table>
            <b-pagination v-model="pagination.current_page" align="center" :per-page="pagination.per_page" :total-rows="filterData.length"></b-pagination>
        </div>
    </div>

    <b-modal @close="addVideo = {} , errors = {}" id="compose-playlist" title="Add/Update Video Playlist" title-class="text-info" header-class="article-header" body-class="article-body" size="lg" centered no-close-on-esc no-close-on-backdrop hide-footer>
        <div class="row">
            <div class="col-md-12">
                <b-form enctype="multipart/form-data">

                            <div v-if="!addVideo.thumbnail">
                                <b-form-file class="my-1 form_control" v-model="addVideo.thumbnail" :state="addVideo.thumbnail ? true : false" placeholder="Thumbnail" @input="create_thumb"></b-form-file>
                                <span v-if="this.errors.thumbnail" class="text-danger">{{...this.errors.thumbnail}}</span>
                            </div>
                            <div v-else class="position-relative">
                                            <span class="img_trash" @click="addVideo.thumbnail = null">
                                                <b-icon icon="trash" class="rounded-circle bg-dark img_trash_icon p-2 h1 text-white" size="md"></b-icon>
                                            </span>
                                <img v-if="typeof addVideo.thumbnail === 'string'" :src="baseURL+addVideo.thumbnail" class="img-thumbnail" alt="gvideo_img">
                                <img v-else :src="client_url" class="img-thumbnail" alt="gvideo_img">
                            </div>
                            <label for="">Select Category</label>
                            <b-form-select v-model="addVideo.category_id" :state="addVideo.category_id ? true : false">
                                <option v-for="(category , index) in AllCategoriesData" :key=index :value=category.id>
                                    {{ category.name }}
                                </option>
                            </b-form-select>
                            <br/>
                            <label for="">Select SubCategory</label>    
                            <b-form-select class="mt-2" v-model="addVideo.subcat_id" :state="addVideo.subcat_id ? true : false" >
                                <option v-for="(item, index) in subCategories"
                                        :key=index
                                        :value=item.id
                                > {{ item.name }} </option>
                            </b-form-select>
                            <span v-if="this.errors.subcat_id" class="text-danger">{{...this.errors.subcat_id}}</span>
                            <br/>
                            <label for="">Select Language</label>
                            <b-form-select class="mt-2" v-model="addVideo.language_id" text="Select Language" :state="addVideo.language_id ? true : false">
                                <option v-for="(language, index) in languageData" :key=index
                                        :value=language.id
                                >
                                    {{ language.name }}
                                </option>
                            </b-form-select>
                            <span v-if="this.errors.language_id" class="text-danger">{{...this.errors.language_id}}</span>

                            <b-form-input
                                    class="mt-2"
                                    v-model="addVideo.url"
                                    :state="addVideo.url ? true : false"
                                    placeholder="paste the youtube playlist id (i.e PL_xxxxxxxxxxxxxxxxxxxxxxxxxx)">
                            </b-form-input>

                            <b-form-input
                                    class="mt-2"
                                    v-model="addVideo.title"
                                    :state="addVideo.title ? true : false"
                                    type="text"
                                    name="title"
                                    :maxlength="500"
                                    placeholder="Title">
                            </b-form-input>

                        <span v-if="this.errors.title" class="text-danger">{{...this.errors.title}}</span>

                            <b-datepicker
                                class="mt-2"
                                v-model="addVideo.date" :state="addVideo.date ? true : false" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric'}">
                            </b-datepicker>
                            <span v-if="this.errors.date" class="text-danger">{{...this.errors.date}}</span>
                             <br/>
                            <label value="null">Select Speaker Name</label>
                            <b-form-select
                                class="mt-2"
                                v-model="addVideo.speaker_id" text="Select Speaker" :state="addVideo.speaker_id ? true : false">
                                <option v-for="speaker in AllSpeakersData"
                                         :key="speaker.id"
                                         :value="speaker.id"
                                >
                                    {{ speaker.name }}
                                </option>
                            </b-form-select>
                            <span v-if="this.errors.speaker_id" class="text-danger">{{...this.errors.speaker_id}}</span>

                            <b-form-textarea
                                    class="mt-2"
                                    v-model="addVideo.description"
                                    maxlength="2000"
                                    :state="addVideo.description ? true : false"
                                    placeholder="Enter something..."
                                    rows="3"
                                    max-rows="10">
                            </b-form-textarea>
                            <span v-if="this.errors.description" class="text-danger">{{...this.errors.description}}</span>

                    <div class="row mt-2">
                        <div class="col-md-6">
                            <b-form-checkbox
                                    class="mt-2 float-left"
                                    switch
                                    v-model="addVideo.status"
                            >
                                Completed
                            </b-form-checkbox>
                        </div>
                        <div class="col-md-6">
                            <div id="check_control" class="form_control text-info float-right cursor-pointer">
                                <b-icon-eye-slash-fill v-if="addVideo.hidden === true" class="h2 text-danger" @click="addVideo.hidden = false"></b-icon-eye-slash-fill>
                                <b-icon-eye-fill v-else class="h2 text-info" @click="addVideo.hidden = true"></b-icon-eye-fill>
                            </div>
                            <span v-if="this.errors.hidden" class="text-danger">{{...this.errors.hidden}}</span>
                        </div>
                    </div>
                    <b-button class="bg-info float-right w-25" @click="saveVideoData">Save</b-button>
                </b-form>
            </div>
        </div>

    </b-modal>
        <b-toast id="success-toast-videos" title="Successfull !" variant="success">
        {{ success_message }}
        </b-toast>
  </div>
<!--</div>-->

</template>
    

<script>
import { mapGetters, mapActions } from 'vuex'
import Loader from '../shared/alhaq_loader'
import config from '../../assets/config'
import moment from 'moment'
export default {
    components: { Loader },
    data() {
      return {
        loader_state: false,
        errors: {},
        success_message: null,
        baseURL: config.baseURL,
        client_url: '',
        toast: {
            show: false,
            msg: '',
            variant: ''
        },
        addVideoFlag: false,
        filter: {
            text: '',
            speaker_id: null,
            lang_id: null,
            subcat_id: null
        },
        fields: [
            { key: 'id', label: 'ID', sortable: true  },
            { key: 'title', label: 'Title', sortable: true  },
            { key: 'date', label: 'Date', sortable: true  },
            { key: 'thumbnail', label: 'Thumbnail'},
            { key: 'url', label: 'URL', sortable: true  },
            { key: 'hidden', label: 'Hidden', sortable: true  },
            { key: 'speaker_details.name', label: 'Speaker', sortable: true  },
            { key: 'language_details.name', label: 'Language', sortable: true  },
            { key: 'subcategory_details.name', label: 'Sub Category', sortable: true },
            { key: 'status', label: 'Status'  },
            { key: 'actions', label: 'Actions' },
            ],
        subCategories: [],
        addVideo: {
            category_id: null,
            title: null,
            url: null,
            thumbnail: null,
            date: null,
            description: null,
            status: false,
            hidden: false,
            subcat_id: null,
            language_id: null,
            speaker_id: null,
        },
        idParams: null,
        nameParams: null,
        pagination: {
              current_page: 1,
              per_page: 25
        },
      }

    },
   
    mounted(){
        this.loader_state = true;
        this.getAllPlaylists()
        this.getAllCategories()
        this.getAllLanguages()
        this.getAllSpeakers()
        this.getAllSubCategories()
        this.loader_state = false;
    },
    methods: {
        ...mapActions([
            'getAllPlaylists',
            'getAllCategories',
            'getAllLanguages',
            'getAllSpeakers',
            'getSinglePlayListWithId',
            'getSubCatWithCategoryId',
            'getAllSubCategories',
            'addPlaylist',
            'updatePlaylistThumb',
            'updatePlaylist',
            'deletePlaylist',
        ]),
        create_thumb () {
            this.client_url = URL.createObjectURL(this.addVideo.thumbnail)
            if (this.addVideo.id) {
                let data = new FormData()
                data.append('id', this.addVideo.id)
                data.append('thumbnail', this.addVideo.thumbnail)
                this.loader_state = true;
                this.updatePlaylistThumb(data).then(response => {
                    if (!response.data.error) {
                        this.success_message = response.data.message
                        this.showToast(response.data.message, 'success')
                        this.getAllPlaylists()
                        this.loader_state = false;
                    }
                })
            }

        },
        addNewVideo(id, title) {
            this.idParams = id 
            this.nameParams = title
            this.addVideoFlag = !this.addVideoFlag
            if(id !== ''){
                this.editItem(id)
            }
        },
        edit (title,payload) {
          this.idParams = payload.id  
          this.nameParams = title
          this.$bvModal.show('compose-playlist')
          let cat_id = payload.subcategory_details ? payload.subcategory_details.category_id : ''
          this.addVideo = payload;
          this.$set(this.addVideo, 'category_id', cat_id)
        },
        showModal() {
        this.$refs['my-modal'].show()
      },
      hideModal() {
        this.$refs['my-modal'].hide()
      },
      saveVideoData(){
          let status = this.addVideo.status == false ? 0 : 1
          let hidden = this.addVideo.hidden == false ? 0 : 1
          let formData = new FormData()
          formData.append('title', this.addVideo.title)
          formData.append('url', this.addVideo.url)
          formData.append('thumbnail', this.addVideo.thumbnail)
          formData.append('date', this.addVideo.date)
          formData.append('description', this.addVideo.description)
          formData.append('status', status)
          formData.append('hidden', hidden)
          formData.append('subcat_id', this.addVideo.subcat_id)
          formData.append('language_id', this.addVideo.language_id)
          formData.append('speaker_id', this.addVideo.speaker_id)
          if(this.idParams === ''){
            this.loader_state = true;
            this.addPlaylist({data: formData}).then(response =>{
              if(response.data.error){
                  this.errors = response.data.data
                  this.loader_state = false;
              }else {
                  this.errors = {}
                  this.getAllPlaylists()
                  this.success_message = response.data.message
                  this.$bvModal.hide('compose-playlist')
                 this.showToast(response.data.message, 'success')
                 this.loader_state = false;
              }
            })
          } else {
              formData.append('id', this.idParams)
              this.loader_state = true;
            this.updatePlaylist({data: formData}).then(response =>{
                if(response.data.error){
                    this.errors = response.data.data
                    this.loader_state = false;
                }else {
                    this.errors = {}
                    this.getAllPlaylists()
                    this.success_message = response.data.message
                    this.$bvModal.hide('compose-playlist')
                   this.showToast(response.data.message, 'success')
                   this.loader_state = false;
                }
            })
          }    
      },
      // get the current selected item from db to update
      editItem(itemId){
          this.getSinglePlayListWithId({id: itemId}).then(response =>{
              this.addVideo = response
          })
      },
      // delete the selected item
      deleteItem(itemId) {
        this.loader_state = true;
        this.deletePlaylist({id: itemId}).then(response => {
            if(response.data.error){
                  this.errors = response.data.data
                  this.loader_state = false;
              }else {
                  this.errors = {}
                  this.getAllPlaylists()
                  this.success_message = response.data.message
                  this.showToast(response.data.message, 'success')
                  this.loader_state = false;
              }
        })
      },
        showToast (msg, variant)  {
            this.toast.show = true
            this.toast.msg = msg
            this.toast.variant = variant
        },
      confirm (payload) {
            this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this resource.', {
                title: 'Confirmation',
                headerClass: 'confirm-header',
                bodyClass: 'confirm-body',
                footerClass: 'confirm-footer',
                okTitle: 'Delete',
                cancelVariant: 'info',
                okVariant: 'danger',
                hideHeaderClose: false,
                centered: true
            }).then(value => {
                if(value) {
                    this.deleteItem(payload)
                }
            })
        },
    },
    computed: {
      ...mapGetters([
        'AllPlaylistsData',
        'AllSubCategoriesData',
        'AllCategoriesData',
        'languageData',
        'AllSpeakersData',
        ]),
        getVideoSeriesDate() {
            return moment(this.AllPlaylistsData.date).locale('en-us').format('DD-MM-YYYY')
        },
        filterData: function () {
            return this.AllPlaylistsData.filter(item => {
                return item.title.toLowerCase().includes(this.filter.text.toLowerCase()) &&
                    (this.filter.lang_id ? item.language_details.id === this.filter.lang_id : true) &&
                    (this.filter.speaker_id ? item.speaker_details.id === this.filter.speaker_id : true) &&
                    (this.filter.subcat_id ? item.subcategory_details.id === this.filter.subcat_id : true)
            });
        }
    },
    watch: {
        'addVideo.category_id': function (to, from) {
            this.loader_state = true;
            this.getSubCatWithCategoryId({id: this.addVideo.category_id}).then(response => {
                this.loader_state = false;
                this.subCategories = response.data.data.sub_categories
            })
        },
    }
}
</script>

<style scoped>
    .description-max{
    width: 200px !important;
  }
    .tableClass >>> tbody  {
        background-color: #15212d;
        color: white !important;
        border: none !important;
    }
    .form_control .form-control,
    .form_control .custom-select
    {
        background-color: transparent !important;
        color: #28abe1;
        cursor: pointer;
    }
    .img_trash {
        position: absolute;
        left: 85% !important;
        top: 5% !important;
        cursor: pointer;
    }
    .img_trash_icon:hover {
        background-color: red !important;
    }
    .tbl_img {
        width: 50px;
        height: 50px;
    }
    .custom-select-options{
        background-color: #15212d;
        color: #28abe1;
    }
</style>