<template>
	<div id="manage-category">
		<Loader :state="loader_state"></Loader>
		<b-toast
			id="success-toast-new"
			v-model="toast.show"
			title="Information"
			:variant="toast.variant"
			class="alert_box text-white"
			dismissible
			dismiss-label="close"
		>
			{{ toast.msg }}
		</b-toast>

		<div class="row mt-5">
			<div class="col-md-10">
				<b-button class="bg-info" v-b-modal.add-category>
					<b-icon-pencil></b-icon-pencil>
					Add Category
				</b-button>
			</div>
			<div class="col-md-2">
				<p class="text-info float-right">Total Records: {{ filterData.length }}</p>
			</div>
		</div>

		<div class="row justify-content-between">
			<div class="col-md-4">
				<b-input-group class="w-75 my-4 form_control float-left">
					<b-form-input type="search" placeholder="Search By Title.." v-model="filter.cat_text"></b-form-input>
					<template v-slot:append>
						<b-input-group-text class="bg-info text-white border-0">
							<b-icon-search></b-icon-search>
						</b-input-group-text>
					</template>
				</b-input-group>
			</div>
			<div class="col-md-4">
				<b-form-select id="pagination_controller" v-model="pagination.per_page" class="w-25 my-4 float-md-right">
					<option>Pagination</option>
					<option value="25">25</option>
					<option value="50">50</option>
					<option value="75">75</option>
					<option value="100">100</option>
					<option value="125">125</option>
				</b-form-select>
			</div>
		</div>

		<div class="row">
			<div class="col-md-12">
				<b-table
					class="tableClass text-left"
					borderless
					:fields="fields"
					:items="filterData"
					:per-page="pagination.per_page"
					:current-page="pagination.current_page"
				>
					<template v-slot:cell(name)="data">
						<p class="text-truncate description-max">{{ data.value }}</p>
					</template>
					<template v-slot:cell(hidden)="data">
						<b-icon-eye-slash-fill v-if="data.value" size="md" class="h3 text-danger"></b-icon-eye-slash-fill>
						<b-icon-eye-fill v-else class="h3 text-info"></b-icon-eye-fill>
					</template>
					<template v-slot:cell(actions)="data">
						<b-button class="btn btn-info table_edit mx-2" @click="edit(data.item)">
							<b-icon-pencil-square></b-icon-pencil-square>
						</b-button>
					</template>
					<template v-slot:cell(created_at)="data">
						{{ getCategoriesDate }}
					</template>
				</b-table>
				<b-pagination
					v-model="pagination.current_page"
					align="center"
					:per-page="pagination.per_page"
					:total-rows="filterData.length"
				></b-pagination>
			</div>
		</div>

		<b-modal
			@close=";(category = {}), (errors = {})"
			id="add-category"
			title="Add Category"
			title-class="text-info"
			size="md"
			centered
			no-close-on-esc
			no-close-on-backdrop
			hide-footer
		>
			<div class="row">
				<div class="col-md-12">
					<b-form @submit.prevent="save">
						<b-form-input
							class="my-1 form_control"
							v-model="category.name"
							required
							placeholder="Category Name"
						></b-form-input>
						<span v-if="this.errors.name" class="text-danger">{{ this.errors.name[0] }}</span>
						<div class="row mt-2">
							<div class="col-md-6">
								<b-input-group id="check_control" class="form_control text-info">
									<b-icon-eye-slash-fill
										v-if="category.hidden"
										class="h2 text-danger"
										@click="category.hidden = false"
									></b-icon-eye-slash-fill>
									<b-icon-eye-fill v-else class="h2 text-info" @click="category.hidden = true"></b-icon-eye-fill>
								</b-input-group>
								<span v-if="this.errors.hidden" class="text-danger">{{ this.errors.hidden[0] }}</span>
							</div>
							<div class="col-md-6">
								<b-button type="submit" class="w-100 bg-info float-right">Save</b-button>
							</div>
						</div>
					</b-form>
				</div>
			</div>
		</b-modal>

		<div class="row mt-5">
			<div class="col-md-10">
				<b-button class="bg-info" v-b-modal.add-sub-category>
					<b-icon-pencil></b-icon-pencil>
					Add SubCategory
				</b-button>
			</div>
			<div class="col-md-2">
				<p class="text-info float-right">Total Records: {{ filterDataSC.length }}</p>
			</div>
		</div>

		<div class="row justify-content-between">
			<div class="col-md-4 col-md-offset-2">
				<b-input-group class="my-4 form_control float-left">
					<b-form-input type="search" placeholder="Search By Title.." v-model="filter.subcat_text"></b-form-input>
					<template v-slot:append>
						<b-input-group-text class="bg-info text-white border-0">
							<b-icon-search></b-icon-search>
						</b-input-group-text>
					</template>
				</b-input-group>
			</div>
			<div class="col-md-4">
				<b-input-group class="my-4 form_control float-right cursor-pointer">
					<template v-slot:prepend v-if="filter.cat_id">
						<b-input-group-text class="bg-info text-white border-0" @click="filter.cat_id = null">
							<b-icon-x></b-icon-x>
						</b-input-group-text>
					</template>
					<b-form-select v-model="filter.cat_id">
						<option value="null">Select Category</option>
						<option v-for="(cat, index) in AllCategoriesData" :key="index" :value="cat.id">
							{{ cat.name }}
						</option>
					</b-form-select>
				</b-input-group>
			</div>

			<div class="col-md-2">
				<b-form-select id="pagination_controller" v-model="pagination.per_page" class="my-4 float-md-right">
					<option>Pagination</option>
					<option value="25">25</option>
					<option value="50">50</option>
					<option value="75">75</option>
					<option value="100">100</option>
					<option value="125">125</option>
				</b-form-select>
			</div>
		</div>

		<div class="row">
			<div class="col-md-12">
				<b-table
					class="tableClass text-left"
					borderless
					:fields="fieldsSC"
					:items="filterDataSC"
					:per-page="pagination.per_page"
					:current-page="pagination.current_page"
				>
					<template v-slot:cell(name)="data">
						<p class="text-truncate description-max">{{ data.value }}</p>
					</template>
					<template v-slot:cell(hidden)="data">
						<b-icon-eye-slash-fill
							v-if="data.item.hidden === true"
							size="md"
							class="h3 text-danger"
						></b-icon-eye-slash-fill>
						<b-icon-eye-fill v-else class="h3 text-info"></b-icon-eye-fill>
					</template>
					<template v-slot:cell(speaker)="data">
						<p class="text-truncate description-max">{{ data.value ? data.value : 'N/A' }}</p>
					</template>
					<template v-slot:cell(description)="data">
						<p class="text-truncate description-max">{{ data.value ? data.value : 'N/A' }}</p>
					</template>
					<template v-slot:cell(category_details)="data">
						<p>{{ data.value ? data.value.name : 'N/A' }}</p>
					</template>
					<template v-slot:cell(actions)="data">
						<b-button class="btn btn-info table_edit mx-2" @click="commentsSCF(data.item)">
							<b-icon-card-text></b-icon-card-text>
						</b-button>
						<b-button class="btn btn-info table_edit mx-2" @click="editSC(data.item)">
							<b-icon-pencil-square></b-icon-pencil-square>
						</b-button>
					</template>
				</b-table>
				<b-pagination
					v-model="pagination.current_page"
					align="center"
					:per-page="pagination.per_page"
					:total-rows="filterData.length"
				></b-pagination>
			</div>
		</div>

		<b-modal
			@close=";(subcategory = {}), (errors = {})"
			id="add-sub-category"
			title="Add SubCategory"
			title-class="text-info"
			size="md"
			centered
			no-close-on-esc
			no-close-on-backdrop
			hide-footer
		>
			<div class="row">
				<div class="col-md-12">
					<b-form @submit.prevent="saveSC">
						<label for="">Select Category</label>
						<b-form-select v-model="subcategory.category_id" :state="subcategory.category_id ? true : false">
							<option v-for="(category, index) in AllCategoriesData" :key="index" :value="category.id">
								{{ category.name }}
							</option>
						</b-form-select>
						<span v-if="this.errors.category_id" class="text-danger">{{ this.errors.category_id[0] }}</span>

						<b-form-input
							class="my-1 form_control"
							v-model="subcategory.name"
							required
							placeholder="SubCategory Name"
						></b-form-input>
						<span v-if="this.errors.name" class="text-danger">{{ this.errors.name[0] }}</span>

						<b-form-input
							class="my-1 form_control"
							v-model="subcategory.speaker"
							placeholder="SubCategory Speaker (Free Text)"
						></b-form-input>
						<span v-if="this.errors.speaker" class="text-danger">{{ this.errors.speaker[0] }}</span>

						<b-form-textarea
							class="mt-2"
							v-model="subcategory.description"
							:state="subcategory.description ? true : false"
							placeholder="Enter something..."
							rows="3"
							max-rows="6"
						>
						</b-form-textarea>
						<span v-if="this.errors.description" class="text-danger">{{ this.errors.description[0] }}</span>

						<b-form-textarea
							class="mt-2"
							v-model="subcategory.comments"
							placeholder="Enter Comments..."
							rows="3"
							max-rows="6"
						>
						</b-form-textarea>
						<span v-if="this.errors.comments" class="text-danger">{{ this.errors.comments[0] }}</span>

						<div class="row mt-2">
							<div class="col-md-6">
								<b-input-group id="check_control" class="form_control text-info">
									<b-icon-eye-slash-fill
										v-if="subcategory.hidden"
										class="h2 text-danger"
										@click="subcategory.hidden = false"
									></b-icon-eye-slash-fill>
									<b-icon-eye-fill v-else class="h2 text-info" @click="subcategory.hidden = true"></b-icon-eye-fill>
								</b-input-group>
								<span v-if="this.errors.hidden" class="text-danger">{{ this.errors.hidden[0] }}</span>
							</div>
							<div class="col-md-6">
								<b-button type="submit" class="w-100 bg-info float-right">Save</b-button>
							</div>
						</div>
					</b-form>
				</div>
			</div>
		</b-modal>

		<b-modal id="commentsSCModal" @close="commentsSC = ''" hide-footer title="Comments">
			{{ commentsSC }}
		</b-modal>
	</div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import config from '../../assets/config'
import Loader from '../shared/alhaq_loader'
import moment from 'moment'

export default {
	components: { Loader },
	data() {
		return {
			loader_state: false,
			category: {
				name: '',
				hidden: '',
			},
			subcategory: {
				name: '',
				hidden: false,
				description: '',
				speaker: '',
				comments: '',
				category_id: '',
			},
			errors: {},
			toast: {
				show: false,
				msg: '',
				variant: '',
			},
			filter: {
				cat_text: '',
				subcat_text: '',
				cat_id: null,
			},
			baseURL: config.baseURL,
			fields: [
				{ key: 'id', label: 'ID', sortable: true },
				{ key: 'name', label: 'Name', sortable: true },
				{ key: 'hidden', label: 'Hidden' },
				{ key: 'created_at', label: 'Date' },
				{ key: 'actions', label: 'Actions' },
			],
			fieldsSC: [
				{ key: 'id', label: 'ID', sortable: true },
				{ key: 'name', label: 'Name', sortable: true },
				{ key: 'speaker', label: 'Speaker' },
				{ key: 'description', label: 'Description' },
				{ key: 'category_details', label: 'Category', sortable: true },
				{ key: 'hidden', label: 'Hidden' },
				{ key: 'actions', label: 'Actions' },
			],
			pagination: {
				current_page: 1,
				per_page: 25,
			},
			paginationSC: {
				current_page: 1,
				per_page: 25,
			},
			commentsSC: '',
		}
	},
	mounted() {
		this.loader_state = true
		this.getAllCategories()
		this.getAllSubCategories()
		this.loader_state = false
	},
	methods: {
		save() {
			if (this.category.id) {
				let data = new FormData()
				data.append('id', this.category.id)
				data.append('name', this.category.name)
				data.append('hidden', this.category.hidden ? 1 : 0)
				this.loader_state = true
				this.updateCategory(data).then((response) => {
					if (response?.data?.error === true) {
						this.errors = response.data.data
						this.loader_state = false
					} else {
						this.errors = {}
						this.showToast(response.data.message, 'success')
						this.category = {}
						this.$bvModal.hide('add-category')
						this.getAllCategories()
						this.loader_state = false
					}
				})
			} else {
				let data = new FormData()
				data.append('name', this.category.name)
				data.append('hidden', this.category.hidden ? 1 : 0)
				this.loader_state = true
				this.addCategory(data).then((response) => {
					if (response.data.error) {
						this.errors = response.data.data
						this.loader_state = false
					} else {
						this.errors = {}
						this.showToast(response.data.message, 'success')
						this.category = {}
						this.$bvModal.hide('add-category')
						this.getAllCategories()
						this.loader_state = false
					}
				})
			}
		},
		saveSC() {
			if (this.subcategory.id) {
				let data = new FormData()
				data.append('id', this.subcategory.id)
				data.append('name', this.subcategory.name)
				data.append('description', this.subcategory.description)
				data.append('hidden', this.subcategory.hidden ? 1 : 0)
				data.append('speaker', this.subcategory.speaker)
				data.append('comments', this.subcategory.comments)
				data.append('category_id', this.subcategory.category_id)
				this.loader_state = true
				this.updateSubCategory(data).then((response) => {
					if (response.data.error === true) {
						this.errors = response.data.data
						this.loader_state = false
					} else {
						this.errors = {}
						this.showToast(response.data.message, 'success')
						this.subcategory = {}
						this.$bvModal.hide('add-sub-category')
						this.getAllSubCategories()
						this.loader_state = false
					}
				})
			} else {
				let data = new FormData()
				data.append('name', this.subcategory.name)
				data.append('description', this.subcategory.description)
				data.append('hidden', this.subcategory.hidden ? 1 : 0)
				data.append('speaker', this.subcategory.speaker)
				data.append('comments', this.subcategory.comments)
				data.append('category_id', this.subcategory.category_id)
				this.loader_state = true
				this.addSubCategory(data).then((response) => {
					if (response.data.error) {
						this.errors = response.data.data
						this.loader_state = false
					} else {
						this.errors = {}
						this.showToast(response.data.message, 'success')
						this.subcategory = {}
						this.$bvModal.hide('add-sub-category')
						this.getAllSubCategories()
						this.loader_state = false
					}
				})
			}
		},

		edit(payload) {
			this.$bvModal.show('add-category')
			this.category = payload
		},
		editSC(payload) {
			this.$bvModal.show('add-sub-category')
			this.subcategory = payload
		},
		showToast(msg, variant) {
			this.toast.show = true
			this.toast.msg = msg
			this.toast.variant = variant
		},
		showToast(msg, variant) {
			this.toast.show = true
			this.toast.msg = msg
			this.toast.variant = variant
		},
		...mapActions([
			'getAllCategories',
			'getAllSubCategories',
			'addCategory',
			'updateCategory',
			'deleteCategory',
			'addSubCategory',
			'updateSubCategory',
			'deleteSubCategory',
		]),
		commentsSCF({ comments }) {
			this.commentsSC = comments
			this.$bvModal.show('commentsSCModal')
		},
	},
	computed: {
		...mapGetters(['AllCategoriesData', 'AllSubCategoriesData']),
		getCategoriesDate() {
			return moment(this.category.created_at).locale('en-us').format('DD-MM-YYYY')
		},
		filterData: function () {
			return this.AllCategoriesData.filter((item) => {
				return item.name.toLowerCase().includes(this.filter.cat_text.toLowerCase())
			})
		},
		filterDataSC: function () {
			return this.AllSubCategoriesData.filter((item) => {
				return (
					item.name.toLowerCase().includes(this.filter.subcat_text.toLowerCase()) &&
					(this.filter.cat_id ? item.category_details.id === this.filter.cat_id : true)
				)
			})
		},
	},
}
</script>

<style scoped>
.tableClass {
	background-color: #15212d;
	color: #28abe1 !important;
	border: none !important;
}
.form_control .form-control,
.form_control .custom-select {
	background-color: transparent !important;
	color: #28abe1;
	cursor: pointer;
}
#check_control {
	cursor: pointer;
}
.description-max {
	width: 200px !important;
}
.custom-select-options {
	background-color: #15212d;
	color: #28abe1;
}
</style>
