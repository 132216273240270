<template>
 <div class="manage-apps mt-5">
    <Loader :state="loader_state"></Loader>
     <b-toast id="success-toast-new" v-model="toast.show" title="Information" :variant="toast.variant" class="alert_box text-white" dismissible dismiss-label="close">
         {{toast.msg}}
     </b-toast>

     <div class="container-fluid">
         <div class="row  w-100">
             <div class="col-md-10">
                 <b-button class="bg-info" v-b-modal.add-apps>
                     <b-icon-pencil></b-icon-pencil>
                    Add Apps
                 </b-button>
             </div>
             <div class="col-md-2">
                 <p class=" text-info float-right">
                     Total Records: {{filterApps.length}}
                 </p>
             </div>
         </div>
         <div class="row justify-content-between">
             <div class="col-md-4">
                 <b-input-group class="w-75 my-4 form_control float-left">
                     <b-form-input type="search" placeholder="Search By Title.." v-model="filter.text"></b-form-input>
                     <template v-slot:append>
                         <b-input-group-text class="bg-info text-white border-0">
                             <b-icon-search></b-icon-search>
                         </b-input-group-text>
                     </template>
                 </b-input-group>
             </div>
             <div class="col-md-2 offset-md-2">
                 <b-input-group class="my-4 w-100 form_control float-right cursor-pointer">
                     <template v-slot:append>
                         <b-input-group-text class="bg-info text-white border-0">
                             <b-icon-soundwave></b-icon-soundwave>
                         </b-input-group-text>
                     </template>
                     <template v-slot:prepend v-if='filter.lang_id'>
                         <b-input-group-text  class="bg-info text-white border-0" @click="filter.lang_id = null">
                             <b-icon-x></b-icon-x>
                         </b-input-group-text>
                     </template>
                     <b-form-select v-model="filter.lang_id">
                         <option value="null">Select Language</option>
                         <option v-for="(lang, index) in languageData" :key=index :value=lang.id>
                             {{ lang.name }}
                         </option>
                     </b-form-select>
                 </b-input-group>
             </div>
             <div class="col-md-2">
                 <b-input-group class="my-4 w-100 form_control float-right cursor-pointer">
                     <template v-slot:append>
                         <b-input-group-text class="bg-info text-white border-0">
                             <b-icon-bounding-box></b-icon-bounding-box>
                         </b-input-group-text>
                     </template>
                     <template v-slot:prepend v-if='filter.subcat_id'>
                         <b-input-group-text  class="bg-info text-white border-0" @click="filter.subcat_id = null">
                             <b-icon-x></b-icon-x>
                         </b-input-group-text>
                     </template>
                     <b-form-select v-model="filter.subcat_id">
                         <option value="null">Select SubCategory Name</option>
                         <option v-for="(subcat, index) in AllSubCategoriesData" :key=index :value=subcat.id>
                             {{ subcat.name }}
                         </option>
                     </b-form-select>
                 </b-input-group>
             </div>
             <div class="col-md-2">
                 <b-form-select id="pagination_controller" v-model="pagination.per_page" class="form_control w-100 my-4 float-md-right">
                     <option>Paginate</option>
                     <option value="25">25</option>
                     <option value="50">50</option>
                     <option value="75">75</option>
                     <option value="100">100</option>
                     <option value="125">125</option>
                 </b-form-select>
             </div>
         </div>
         <div class="row">
             <div class="col-md-12 mt-4">
                         <b-table class="tableClass text-left" borderless :fields="fields" :items="filterApps" :per-page="pagination.per_page" :current-page="pagination.current_page">
                         <template v-slot:cell(title)="data">
                            <p class="text-truncate description-max">{{data.item.title}}</p> 
                         </template>
                             <template v-slot:cell(hidden)="data">
                                 <b-icon-eye-slash-fill v-if="data.item.hidden" size="md" class="h3 text-danger"></b-icon-eye-slash-fill>
                                 <b-icon-eye-fill v-else class="h3 text-info"></b-icon-eye-fill>
                             </template>
                         <template v-slot:cell(thumbnail)="data">
                             <img :src=baseURL+data.item.thumbnail alt="app_img" class="apps-thumbnail"/>
                         </template>
                         <template v-slot:cell(description)="data">
                             <p class="text-truncate description-max">{{data.value ? data.value : 'NULL'}}</p>
                         </template>
                         <template v-slot:cell(android_link)="data">
                             <p :href="data.item.android_link" class="app-link text-truncate description-max">{{data.item.android_link}}</p>
                         </template>
                         <template v-slot:cell(ios_link)="data">
                             <p :href="data.item.ios_link" class="app-link text-truncate description-max">{{data.item.android_link}}</p>
                         </template>
                         <template v-slot:cell(subcat_details)="data">
                             {{data.value.name}}
                         </template>
                         <template v-slot:cell(language_details)="data">
                             {{data.value.name}}
                         </template>
                         <template v-slot:cell(actions)="data">
                             <b-button class="btn btn-info table_edit mx-2" @click="edit(data.item)">
                                 <b-icon-pencil-square></b-icon-pencil-square>
                             </b-button>
                             <b-button class="btn btn-danger mx-2 table_delete" v-b-modal.confirm-remove @click="confirm(data.item)">
                                 <b-icon-trash></b-icon-trash>
                             </b-button>
                         </template>
                     </b-table>
                 <b-pagination v-model="pagination.current_page" align="center" :per-page="pagination.per_page" :total-rows="filterApps.length"></b-pagination>
             </div>
         </div>
     </div>
     <div>
         <b-modal @close="apps = {}, errors = {}"  id="add-apps" title="Add Apps Details" title-class="text-info" header-class="article-header" body-class="article-body" size="lg" centered no-close-on-esc no-close-on-backdrop hide-footer>
                 <div class="container-fluid">
                     <div class="row ">
                         <div class="col-md-12  w-100">
                             <div v-if="!apps.thumbnail">
                                 <b-form-file :state="apps.thumbnail ? true : false"  v-model="apps.thumbnail" placeholder="Thumbnail" @input="update_thumb"></b-form-file>
                                 <span v-if="this.errors.thumbnail" class="text-danger">{{...this.errors.thumbnail}}</span>
                             </div>
                             <div v-else class="position-relative">
                                 <span class="img_trash" @click="apps.thumbnail = null">
                                <b-icon icon="trash" class="rounded-circle bg-dark img_trash_icon p-2 h1 text-white" size="md"></b-icon>
                            </span>
                                 <img v-if="typeof apps.thumbnail === 'string'" :src="baseURL+apps.thumbnail" class="img-thumbnail" alt="app_img">
                                 <img v-else :src="client_url" class="img-thumbnail" alt="app_img">
                             </div>
                         </div>
                     </div>
                     <div class="row mt-3">
                         <div class="col-md-12 w-100">
                             <label for="">Select Category Name</label>
                             <b-form-select v-model="apps.category_id" :state="apps.category_id ? true : false">
                                 <option v-for="(category,index) in AllCategoriesData" :key=index :value=category.id>
                                     {{ category.name }}
                                 </option>
                             </b-form-select>
                             <div>
                                 <span v-if="this.errors.category_id" class="text-danger">{{...this.errors.category_id}}</span>
                             </div>
                         </div>
                     </div>
                     <div class="row mt-3">
                         <div class="col-md-12  w-100">
                             <label for="">Select Subcategory Name</label>
                             <b-form-select  v-model="apps.subcat_id" :state="apps.subcat_id ? true : false">
                                 <option v-for="(subcat,index) in subCategories" :key=index :value=subcat.id>
                                     {{ subcat.name }}
                                 </option>
                             </b-form-select>
                             <div>
                                 <span v-if="this.errors.subcat_id" class="text-danger">{{...this.errors.subcat_id}}</span>
                             </div>
                         </div>
                     </div>
                     <div class="row mt-3">
                         <div class="col-md-12 w-100">
                             <label for="">Select Language</label>
                             <b-form-select v-model="apps.language_id" :state="apps.language_id ? true : false">
                                 <option v-for="(lang,index) in languageData" :key="index" :value="lang.id">{{lang.name}}</option>
                             </b-form-select>
                             <div>
                                 <span v-if="this.errors.language_id" class="text-danger">{{...this.errors.language_id}}</span>
                             </div>
                         </div>
                     </div>
                     <div class="row mt-3">
                         <div class="col-md-12 w-100">
                             <div>
                                 <b-input placeholder="Title" :maxlength="500" v-model="apps.title" :state="apps.title ? true : false"></b-input>
                                 <span v-if="this.errors.title" class="text-danger">{{...this.errors.title}}</span>
                             </div>
                         </div>
                     </div>
                     <div class="row mt-3">
                         <div class="col-md-12  w-100">
                             <div>
                                 <b-input placeholder="Android" v-model="apps.android_link"></b-input>
                                 <span v-if="this.errors.android_link" class="text-danger ">{{...this.errors.android_link}}</span>
                             </div>
                         </div>
                     </div>
                     <div class="row mt-3">
                         <div class="col-md-12 w-100">
                                 <div>
                                     <b-input placeholder="IOS" v-model="apps.ios_link"></b-input>
                                     <span v-if="this.errors.ios_link" class="text-danger">{{...this.errors.ios_link}}</span>
                                 </div>
                         </div>
                     </div>
                     <div class="row mt-3">
                         <div class="col-md-12 w-100">
                             <div>
                                 <b-datepicker :state="apps.date ? true : false" direction="right to left" v-model="apps.date" placeholder="Date" :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric'}"></b-datepicker>
                                 <span v-if="this.errors.date" class="text-danger">{{...this.errors.date}}</span>
                             </div>
                         </div>
                     </div>
                     <div class="row mt-3">
                         <div class="col-md-12 w-100">
                             <div class="TextArea">
                                 <b-form-textarea
                                         id="textarea-no-auto-shrink"
                                         placeholder="Enter Description"
                                         maxlength="2000"
                                         rows="3"
                                         v-model="apps.description"
                                         :autofocus="false"
                                         no-auto-shrink
                                 ></b-form-textarea>
                             </div>
                             <span v-if="this.errors.description" class="text-danger">{{...this.errors.description}}</span>
                         </div>
                     </div>
                     <div class="row mt-3">
                         <div class="col-md-12">
                             <div class="w-100">
                                 <b-icon-eye-slash-fill v-if="apps.hidden" class="h2 text-danger" v-model="apps.hidden" @click="apps.hidden = false"></b-icon-eye-slash-fill>
                                 <b-icon-eye-fill v-else class="h2 text-info" v-model="apps.hidden" @click="apps.hidden = true"></b-icon-eye-fill>
                             </div>
                         </div>
                     </div>
                     <div class="row justify-content-end">
                         <div class="col-md-4">
                            <b-button block class="float-right" variant="info" @click="addApps">Save</b-button>
                         </div>
                     </div>
                 </div>
         </b-modal>
     </div>
     <b-toast id="success-toast-apps" title="Successfull !" variant="success">
         {{ success_message }}
     </b-toast>
 </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Loader from '../shared/alhaq_loader'
import config from '../../assets/config'
import moment from 'moment'
export default {
    components: { Loader },
    data(){
        return  {
            selected: null,
            loader_state: false,
            date : '',
            errors : {},
            success_message : '',
            client_url : '',
            apps: {
                'language_id': null,
                'subcat_id': null,
                'category_id': null,
                'hidden' : false,
                'android_link' : null,
                'ios_link' : null,
                'title' :  null,
                'thumbnail' : null,
                'date' : null,
                'description': null,
            },
            toast: {
                show: false,
                msg: '',
                variant: ''
            },
            filter: {
                text: '',
                lang_id: null,
                category_id: null,
                subcat_id: null,
                link: null
            },
            pagination: {
                current_page: 1,
                per_page: 25
            },
            subCategories : [],
            baseURL: config.baseURL,
            ModalClose : false,
            fields: [
                {key: 'id', label: 'ID', sortable: true },
                {key: 'title', label: 'Title', sortable: true },
                {key: 'description', label: 'Description' },
                {key: 'hidden', label: 'Hidden', sortable: true },
                { key: 'thumbnail', label: 'Thumbnail'},
                { key: 'android_link', label: 'Android'},
                { key: 'ios_link', label: 'IOS'},
                { key: 'subcat_details', label: 'Sub Category', sortable: true},
                {key :'language_details', label: 'Language', sortable: true},
                { key: 'actions', label: 'Actions'},
                ],
        }
    },
    mounted() {
        this.loader_state = true;
        this.getAllApps();
        this.getAllLanguages();
        this.getAllSubCategories();
        this.getAllCategories();
        this.loader_state = false;
    },
    filters: {
        moment: function (date) {
            if (date) {
                return moment(date).format("MMM Do YYYY")
            }
        },
    },
    watch: {
        'apps.category_id': function (to, from) {
            this.loader_state = true;
            this.getSubCatWithCategoryId({id: this.apps.category_id}).then(response => {
                this.loader_state = false;
                this.subCategories = response.data.data.sub_categories
            })
        },
    },
    methods :{
    ...mapActions([
        'getAllApps',
        'getAllLanguages',
        'getAllSubCategories',
        'getAllCategories',
        'storeApps',
        'getSubCatWithCategoryId',
        'updateAppThumb',
        'updateApps',
        'deleteApps'
    ]),
    edit(payload){
        this.$bvModal.show('add-apps')
        this.apps = payload;
        this.apps.category_id = payload.subcat_details ? payload.subcat_details.category_id : false;
    },
    update_thumb () {
        this.client_url = URL.createObjectURL(this.apps.thumbnail)
        if (this.apps.id) {
            let data = new FormData()
            data.append('id', this.apps.id)
            data.append('thumbnail', this.apps.thumbnail)
            this.loader_state = true;
            this.updateAppThumb(data).then(response => {
                this.loader_state = false;
                if (!response.data.error) {
                    this.success_message = response.data.message
                    this.showToast(response.data.message, 'success')
                    this.getAllApps();
                }
            })
        }

    },
    confirm (payload) {
        this.$bvModal.msgBoxConfirm('Please confirm that you want to delete this resource.', {
            title: 'Confirmation',
            headerClass: 'confirm-header',
            bodyClass: 'confirm-body',
            footerClass: 'confirm-footer',
            okTitle: 'Delete',
            cancelVariant: 'info',
            okVariant: 'danger',
            hideHeaderClose: false,
            centered: true
        }).then(value => {
            if(value) {
                this.remove(payload)
            }
        })

    },
    remove (payload) {
        this.loader_state = true;
        this.deleteApps({'id':payload.id}).then(response => {
            this.loader_state = false
            if (response.data.error) {
                this.errors = response.data.data;
            } else {
                this.loader_state = true;
                this.getAllApps();
                this.loader_state = false;
            }
        });
    },
    addApps(){
        if(this.apps.id){
            let data = new FormData();
            data.append('id', this.apps.id);
            data.append('category_id', this.apps.category_id);
            data.append('subcat_id', this.apps.subcat_id);
            data.append('language_id', this.apps.language_id);
            data.append('title', this.apps.title);
            data.append('android_link', this.apps.android_link);
            data.append('ios_link', this.apps.ios_link);
            data.append('date', this.apps.date);
            data.append('description', this.apps.description);
            data.append('hidden', this.apps.hidden ? 1 : 0);
            this.loader_state = true;
            this.updateApps(data).then(response => {
                this.loader_state = false;
                if (response.data.error ===  true) {
                    this.errors = response.data.data;
                } else {
                    this.errors = {}
                    this.success_message = response.data.message;
                    this.showToast(response.data.message, 'success')
                    this.apps = {};
                    this.$bvModal.hide('add-apps');
                    this.getAllApps();
                }
            })
        }
        if(!this.apps.id) {
            let data = new FormData();
            data.append('category_id', this.apps.category_id);
            data.append('subcat_id', this.apps.subcat_id);
            data.append('language_id', this.apps.language_id);
            data.append('title', this.apps.title);
            data.append('android_link', this.apps.android_link);
            data.append('ios_link', this.apps.ios_link);
            data.append('thumbnail', this.apps.thumbnail);
            data.append('date', this.apps.date);
            data.append('description', this.apps.description);
            data.append('hidden', this.apps.hidden  ? 1 : 0);
            this.loader_state = true;
            this.storeApps(data).then(response => {
                this.loader_state = false;
                if (response.data.error) {
                    this.errors = response.data.data;
                } else {
                    this.errors = {}
                    this.success_message = response.data.message;
                    this.$bvModal.hide('add-apps');
                    this.showToast(response.data.message, 'success')
                    this.getAllApps();
                }
            })
        }
    },
    showToast (msg, variant)  {
        this.toast.show = true
        this.toast.msg = msg
        this.toast.variant = variant
    },
    },
    computed: {
        ...mapGetters([
            'AllAppsData',
            'languageData',
            'AllSubCategoriesData',
            'AllCategoriesData'
        ]),
        filterApps: function () {
            return this.AllAppsData.filter(item => {
                return item.title.toLowerCase().includes(this.filter.text.toLowerCase()) &&
                    ( this.filter.category_id ? item.category_details.id === this.filter.category_id : true) &&
                    ( this.filter.lang_id ? item.language_details.id === this.filter.lang_id : true) &&
                    ( this.filter.subcat_id ? item.subcat_details.id === this.filter.subcat_id : true)
            });
        }
    },
}
</script>

<style scoped>
    .btnClass {
        width: 18%;
    }
    .tableClass >>> tbody  {
        background-color: #15212d;
        color: white !important;
        border: none !important;
    }
    .apps-thumbnail {
        width: 50px;
        height: 50px;
    }
    .img_trash_icon:hover {
        background-color: red !important;
    }
    .form_control .form-control,
    .form_control .custom-select
    {
        background-color: transparent !important;
        color: #28abe1;
        cursor: pointer;
    }
    .description-max{
        width: 100px !important;
    }
    .img_trash {
        position: absolute;
        left: 90% !important;
        top: 1% !important;
        cursor: pointer;
    }
    .app-link{
        color: white !important;
        text-decoration-line: none;
        cursor: pointer;
    }
    .custom-select-options{
        background-color: #15212d;
        color: #28abe1;
    }

</style>